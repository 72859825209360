function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
var Thumbnails = function () {
    function Thumbnails(options) {
        _classCallCheck(this, Thumbnails);
        this.container = options.container;
        this.barWidth = options.barWidth;
        this.container.style.backgroundImage = 'url(\''.concat(options.url, '\')');
        this.events = options.events;
    }
    _createClass(Thumbnails, [
        {
            key: 'resize',
            value: function resize(width, height, barWrapWidth) {
                this.container.style.width = ''.concat(width, 'px');
                this.container.style.height = ''.concat(height, 'px');
                this.container.style.top = ''.concat(-height + 2, 'px');
                this.barWidth = barWrapWidth;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.container.style.display = 'block';
                this.events && this.events.trigger('thumbnails_show');
            }
        },
        {
            key: 'move',
            value: function move(position) {
                this.container.style.backgroundPosition = '-'.concat((Math.ceil(position / this.barWidth * 100) - 1) * 160, 'px 0');
                this.container.style.left = ''.concat(Math.min(Math.max(position - this.container.offsetWidth / 2, -10), this.barWidth - 150), 'px');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.container.style.display = 'none';
                this.events && this.events.trigger('thumbnails_hide');
            }
        }
    ]);
    return Thumbnails;
}();
export default Thumbnails;