import FloatLR from "@/components/floatLR";
import Footer from "@/components/footer";
import CategoryHead from "@/components/categoryHead";
import Video from "@/components/video";
import useAxios from "@/hooks/useAxios";
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom";
import u, { ELocalKey } from "@/util";
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import TeseStyle from "@/pages/main/styles/tese.module.css"
import Button from "@/components/button";
import useCategory from "@/hooks/useCategory";
import useConfBase from "@/hooks/useConfBase";



const VideoId: FC = () => {
    const backImg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAjhJREFUWEftljuIE0EYx78vDwyIjyaFdtpoFdjZiSCpbHwUZxe9vVhrcYdYWiiKYGElhy9QfJ5oIbaCIGITkDAzpkmjkAVrReEs1iL5y8ispJBkd7Nkm9tuh5n9/fb/zXwMU8EPF8ynLYHcE2g2m00A6wB+12q1k91ud3NamXMVEEIcZ+ZHRLTHQpn5iFLqw0IEhBAdZn5IRNsc/JJS6vqsTZ5LAr7vnyei9RgGYM0Yc2cW/K9okknT5kgprwK4MjGno7V+kfS7cwkIIW4x85qD/WTmjlLqTVJ45gTa7XZ5OBxuEFHgYCGAFWPMxzTwTAKe59VLpdJzIjrqYJ/K5fJyr9f7nBaeWsD3/YPMvAFAOth7Ijqttf6WBZ5KQErZAmBj32cXAnhtjDlFROOs8MQCQoglZrax73SwB1rrs/OA47UzT0Gr1doRRdFXItrtFt3QWl/MA54ogUajsb1arYZEVC9EwEJdCWz9d7k2e18pdS6PFGaWIIZ4nnfYHb/9buyV1np5IZswlpBSHgDwjIgOuZPwrlKp2B7wPWsaiROYSKLOzE+Z+YQb06PRKOj3+1+ySKQWsBDbisMwfALgjIMOmTlQSvXSSmQSmCjJTQAX3PsPAIEx5m0aibkELMj3/ctEdO1fY2FeUUq9TCoxt4A7pqvMfHsCuqq1vptEIhcBC5JSBgAeF3Ili/9UCHHMXUr3uoa1uEtpLGGv5ePx+B4RbUZRtDQYDH5NK0VuJUhS7//N2RIoPIE/bp7AIawLvYQAAAAASUVORK5CYII=`
    const { id, channel } = useParams()
    const { req } = useAxios("shipin/detail", "post",)
    const { req: req2 } = useAxios("tese/play", "post",)
    const { req: req3 } = useAxios("tese/detail", "post");
    const { req: req4 } = useAxios("topic/play", "post");
    const { req: reqRandom } = useAxios("video/random", 'post',);
    const { req: reqRandomLike } = useAxios("video/randomLike", 'post',);
    const { req: otherVid } = useAxios("video/other_details", 'post',);


    const category = useCategory()

    const [title, setTitle] = useState<string>("");
    const [data, setData] = useState<any>({});
    const [videourl, setVideoUrl] = useState<string>("");
    const [sourceurl, setSourceUrl] = useState<string>("");
    const [info, setInfo] = useState<any>(null)
    const [tabInfo, setTabInfo] = useState<any>(null)
    const [relatedVidList, setRelatedVidList] = useState<any>(null)
    const [likeVidList, setLikeVidList] = useState<any>(null)
    const [otherList, setOtherList] = useState<any>(null)
    const [reset, setReset] = useState<boolean>(false)
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [config] = useConfBase()
        const [isMobile, setIsMobile] = useState(false);
    

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600); // Set true if screen width is 768px or less (mobile)
        };
    
        useEffect(() => {
            // Add event listener to track window size changes
            window.addEventListener('resize', handleResize);
    
            // Initial check for screen size when the component mounts
            handleResize();
    
            // Cleanup the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []);




    const handleIdClick = (url: string) => {
        window.open(url, '_blank'); // Open the URL in a new tab
    };


    useEffect(() => {
        if (window.location.href.indexOf("tese") === -1) return;
        let val = localStorage.getItem(ELocalKey._TESE);
        try {
            val = JSON.parse(u.decrypt(val))
        } catch {
            return;
        }
        setInfo(val)
    }, [])

    const fetchVideodetails = useCallback(async (category: any) => {
        if (!id) return;
        let fn = channel === "tese" ? req2 : req
        switch (channel) {
            case "tese":
                fn = req2;
                break;
            case "shipin":
                fn = req;
                break;
            case "topic":
                fn = req4; // add on topic API
                break;
            default:
                fn = req;
                break;
        }
        const res = await fn({ id: id })
        if (res) {
            console.log('viddetail', res)
            console.log('viddetail category', category)
            const search = u.searchIdsByChannelName(category, res?.data?.info?.channel, res?.data?.info?.jump_name); // TODO
            setTabInfo(search);
            console.log('search', search)

            const relatedRes = await reqRandom({ channel: res?.data?.info?.channel, tags: res?.data?.info?.jump_name })
            const likeRes = await reqRandomLike()
            const othervid = await otherVid()
            console.log('relatedRes', relatedRes)
            console.log('otherVid', othervid)
            if (relatedRes && relatedRes.data && relatedRes.data.videos_groups) {
                setRelatedVidList((relatedRes.data.videos_groups))
            }
            console.log('likeRes', likeRes)
            if (likeRes && likeRes.data && likeRes.data.videos_groups) {
                setLikeVidList((likeRes.data.videos_groups))
            }

            if (othervid && othervid.data && othervid.data.config) {
                setOtherList((othervid?.data))
                console.log('player_config2:', othervid?.data?.config?.player_config_2); // Check if this logs correctly

            } else {
                console.log('Config is null or undefined');
            }

            try{
                let __data = localStorage.getItem("_USER");
                if(__data){
                    let userinfo = JSON.parse(u.decrypt(__data));
                    if(userinfo && userinfo.username){
                        u.sendVIPLogDomain(userinfo.username, window.location.href +' from video page - ' + res?.data?.info?.channel + '-' + u.isVip() + '[r] - ' + ((res?.data?.info?.channel === "vip" || res?.data?.info?.channel === "remen") && !u.isVip()));
                    }
                }
            }catch(err){}

            if (res?.data?.info?.channel === 'vip' || res?.data?.info?.channel === 'remen') {
                await fetchVidUrl({ id: res?.data?.info?.provider_id, page: res?.data?.ad?.site });
                setTitle(res?.data?.info?.title)
                setVideoUrl(res?.data?.source?.video_url)
                setData(res?.data)

                console.log('show channel', channel)
                console.log('show channel', u.isVip())

                if(!window.location.href.includes('topic')){

                    if (u.isLogin() && !u.isVip()) {
                        return window.location.href = "/user/buy"
                    }

                    if (!u.isVip()) {
                        return window.location.href = "/login"
                    }
                }

                if (!u.isLogin()) {
                    
                    return window.location.href = "/login"
                }
                if (u.isVip()) {

                    if (channel === "tese") {
                        await fetchVidUrl({ id: res?.data?.info?.provider_id, page: res?.data?.ad.site });
                    }
                    setTitle(res?.data?.info?.title)
                    setVideoUrl(res?.data?.source?.video_url)
                    setData(res?.data)
                }
            } else {
                if (channel === "tese") {
                    await fetchVidUrl({ id: res?.data?.info?.provider_id, page: res?.data?.ad.site });
                }
                setTitle(res?.data?.info?.title)
                setVideoUrl(res?.data?.source?.video_url)

                setData(res?.data)
            }
            console.log('video_url', res?.data?.source?.video_url)
        }
        setIsLoad(true);
        setReset(false)

    }, [id, channel, req2, req])

    const fetchVidUrl = useCallback(async (details: any) => {
        const res = await req3(details)
        if (res) {
            setSourceUrl(res?.data?.host?.play_domain)
        }
    }, [req3])

    useEffect(() => {
        if (category.length) {
            fetchVideodetails(category)
        }
    }, [category])

    const isTese = useMemo(() => {
        return window.location.href.indexOf("tese") >= 0
    }, [])

    const hasTag = useMemo(() => {
        return window.location.href.indexOf("tese") >= 0
    }, [])

    const goExternal = useCallback(() => {
        // const __val = u.getStatsParams(false, "tese");
        // await req2({ ...__val, category: "external", name: info?.url })
        return window.open(info?.url)
    }, [info])

    const redirectTop = useCallback((type: string, dataInfo: any, categoryList: any) => {
        if(type === 'topicMain'){
            return window.location.href = '/topic'
        }
        
        console.log('redirectTop dataInfo', dataInfo)
        console.log('redirectTop categoryList', categoryList)
        const search = u.searchIdsByChannelName(categoryList, dataInfo?.info?.channel, dataInfo?.info?.jump_name); // TODO
        console.log('redirectTop search', search)

        if (type === 'home') {
            return window.location.href = '/home'
        }
        if (type === 'main') {
            return;
            // return window.location.href = '/page/'+search.channelInfo?.channel + '/' + search.channelInfo?.id
        }
        if (type === 'sub') {
            if (window.location.href.indexOf("tese") >= 0) {
                return window.location.href = '/page/tese/' + dataInfo?.host?.id;
            }
            let path = (search.subInfo) ? (search.channelInfo?.channel + '/' + search.subInfo?.id) : (search.channelInfo?.channel + '/' + dataInfo?.info?.topic_id);
            return window.location.href = '/page/' + path
        }
    }, [])

    console.log('show and view data', data)

    return (
        isLoad ? (
            <div>
            <CategoryHead showCategory={true} flag={0}>
                {
                    isTese && <div className={`mt20 mw1100 ${TeseStyle.ts_container} ${TeseStyle.main}`}>
                        <div onClick={goExternal}>
                            <Img src={info?.logo} cls={`${TeseStyle.ts_logo}`} getFullImgUrl/>
                        </div>

                        <div>
                            <div className={TeseStyle.ts_title}>{info?.nickname}</div>
                            <div className={TeseStyle.desc}>
                                {info?.desc}
                            </div>
                        </div>

                        <div className={TeseStyle.left_cover}>
                            <Button event={goExternal} cls={`btn_sty2`}>
                                进入官网
                            </Button>
                        </div>
                    </div>
                }

                {
                    isTese && <div className={`${TeseStyle.ts_container} ${TeseStyle.main1} ${TeseStyle.ts_vid_container}`}>
                        <div onClick={goExternal}>
                            <Img src={appendHost(info?.logo)} cls={`${TeseStyle.ts_logo}`} />
                        </div>
                        <div className={TeseStyle.ts_title} onClick={goExternal}>
                            {info?.nickname}
                        </div>
                        <div>
                            <Button event={goExternal} cls={`btn_sty2 ${TeseStyle.ts_btn}`}>
                                进入官网
                            </Button>
                        </div>
                    </div>
                }

                <div className="grid gap10">
                    <div className={`mw1100`} style={{ paddingTop: '20px' }}>
                                            {/* <div className={`${TeseStyle.list_fushou}`}>
                                                {otherList?.ads.player_assistant ? (
                                                   otherList.ads.player_theme_1.map((player: any, index: number) => (
                                                    <div style={{textAlign:'center'}}>
                                                      <Img reload={reset} encryptUrl={player.img} cls="i_cvr" src={player.img} style={{width:'0%', height:'0%'}}>
                                                            {(_decryptUrl: any) => {
                                                                return <>
                                                                    {_decryptUrl &&
                                                                        <Img reload={reset} cls="lazy list icon" encryptUrl={player.img} style={{
                                                                            objectFit: 'contain'
                                                                        }} src={_decryptUrl} width={45} height={45}/>
                                                                    }
                                                                </>
                                                            }}
                                                        </Img>
                                                        <div className={TeseStyle.truncate} style={{paddingBottom:'15px', paddingTop:'5px', fontSize:'13px', fontWeight:'500'}}>{player.name}</div>

                                                    </div>
  
                                                    ))
                                                ) : (
                                                    <div>加载中...</div>
                                                )}
                                            </div> */}
                                            <hr />
                                        </div>
                    <div className={` ${TeseStyle.listinfo}`} style={{textAlign:'center'}}>
                            <div onClick={() => window.open(window.location.origin)} className={TeseStyle.withtransparency} style={{paddingBlock:'12px', cursor: 'pointer'}}>
                                <div className={` ${TeseStyle.boxinternal}`}>
                                <Img src={appendHost("/images/video/info/guoneirukou.png")} width={isMobile ? 25 : 35} height={isMobile ? 25 : 35} cls={``} style={isMobile ? {paddingLeft:'0'} : {paddingInline: '10px'}}/>
                                <div style={{width:'fit-content'}}>
                                <div className={` ${TeseStyle.urlbox}`}>
                                {otherList?.config?.player_config_1?.player_config1_entry ? (
                                    <div >{window.location.origin.replaceAll('https://','').replaceAll('http://','')}</div>
                                ) : (
                                    <p>加载中...</p>

                                )}
                            </div>
                            <div style={{fontSize:'12px', fontWeight:'400', color:'#A9A9A9', textAlign:'center', marginTop:'5px'}}>
                                猫咪AV国内最新入口
                            </div>
                                </div>
                                </div>
                            </div>
                            <div  onClick={() => window.open(window.location.origin+"/download")} className={TeseStyle.withtransparency} style={{paddingBlock:'12px', cursor: 'pointer'}}>
                                <div className={` ${TeseStyle.boxinternal}`}>
                                <Img src={appendHost("/images/video/info/shipinkadun.png")} width={isMobile ? 25 : 35} height={isMobile ? 25 : 35} cls={``} style={isMobile ? {paddingLeft:'0'} : {paddingInline: '10px'}}/>
                                <div style={{width:'fit-content'}}>
                                <div className={` ${TeseStyle.urlbox}`}>
                                {otherList?.config?.player_config_1?.player_config1_entry ? (
                                    <div>安装猫咪APP</div>
                                ) : (
                                    <p>加载中...</p>

                                )}
                            </div>
                            <div style={{fontSize:'12px', fontWeight:'400', color:'#A9A9A9', textAlign:'center', marginTop:'5px'}}>
                                高效稳定低延迟
                            </div>
                                </div>
                                </div>
                            </div>
                            <div  onClick={() => window.open(window.location.origin)} className={TeseStyle.withtransparency} style={{paddingBlock:'12px', cursor: 'pointer'}}>
                                <div className={` ${TeseStyle.boxinternal}`}>
                                <Img src={appendHost("/images/video/info/huijiadizhi.png")} width={isMobile ? 25 : 35} height={isMobile ? 25 : 35} cls={``} style={isMobile ? {paddingLeft:'0'} : {paddingInline: '10px'}}/>
                                <div style={{width:'fit-content'}}>
                                <div className={` ${TeseStyle.urlbox}`}>
                                {otherList?.config?.player_config_1?.player_config1_entry ? (
                                    <div>猫咪AV回家路查看</div>
                                ) : (
                                    <p>加载中...</p>

                                )}
                            </div>
                            <div style={{fontSize:'12px', fontWeight:'400', color:'#A9A9A9', textAlign:'center', marginTop:'5px'}}>
                                猫咪回家地址
                            </div>
                                </div>
                                </div>
                            </div>
                            <div  onClick={() => window.open(config?.customer_service_url)} className={TeseStyle.withtransparency} style={{paddingBlock:'12px', cursor: 'pointer'}}>
                                <div className={` ${TeseStyle.boxinternal}`}>
                                <Img src={appendHost("/images/video/info/youjiangfankui.png")} width={isMobile ? 25 : 35} height={isMobile ? 25 : 35} cls={``} style={isMobile ? {paddingLeft:'0'} : {paddingInline: '10px'}}/>
                                <div style={{width:'fit-content'}}>
                                <div className={` ${TeseStyle.urlbox}`}>
                                {otherList?.config?.player_config_1?.player_config1_entry ? (
                                    <div>有奖反馈</div>
                                ) : (
                                    <p>加载中...</p>

                                )}
                            </div>
                            <div style={{fontSize:'12px', fontWeight:'400', color:'#A9A9A9', textAlign:'center', marginTop:'5px'}}>
                                填写反馈获得奖励
                            </div>
                                </div>
                                </div>
                            </div>
                    </div>
                    <hr />

                    <div className={`mw1100 mt20 ${TeseStyle.header_title} fl`} style={{ gap: '6px' }}>
                        <span className={`${TeseStyle.direct_link} ${TeseStyle.home_direct_link}`} style={{ fontWeight: '600', color: '#e77996', display: 'flex', justifyContent: 'center', gap: '2px' }} onClick={() => { redirectTop('home', data, category) }}>
                            <Img src={appendHost("/images/header/home_selected.png")} width={20} height={20} cls={` ${TeseStyle.home_icon}`} />首页
                        </span>

                        {window.location.href.indexOf("tese") >= 0 &&
                            <>
                                <img className={` ${TeseStyle.arrow}`} src={backImg} alt="" />
                                <span className={`${TeseStyle.direct_link} ${TeseStyle.sub_link}`} onClick={() => { redirectTop('sub', data, category) }}>{(tabInfo?.subInfo?.name ?? data?.host?.nickname)}</span>
                            </>}
                        {window.location.href.indexOf("tese") === -1 &&
                            <>
                                <img className={` ${TeseStyle.arrow}`} src={backImg} alt="" />
                                <span className={`${TeseStyle.direct_link}  ${TeseStyle.sub_link}`} onClick={() => { redirectTop('main', data, category) }}>{(tabInfo?.channelInfo?.name)}</span>
                                <img className={` ${TeseStyle.arrow}`} src={backImg} alt="" />
                                <span className={`${TeseStyle.direct_link}  ${TeseStyle.sub_link}`} onClick={() => { redirectTop('sub', data, category) }}>{(tabInfo?.subInfo?.name ?? data?.info?.topic_title)}</span>
                            </>}
                    </div>
                    <div className="mw1100 m0auto">
                        {/* <hr/> */}
                        <Video otherList={otherList} relatedVidList={relatedVidList} likeVidList={likeVidList} hideBuyCard={isTese} hideMiddle={isTese} data={{ ...data, _info: info }} title={title} vidurl={videourl} sourceurl={sourceurl} tag={hasTag ?
                            <div className="fl point gap10 align_center" onClick={goExternal}>
                                <Img cls={TeseStyle.ts_de_img} src={appendHost(info?.logo)} width={15} height={15} />
                                <div className={TeseStyle.ts_detail}>
                                    {info?.nickname}
                                </div>
                            </div> : ''
                        } />
                    </div>
                    <Footer />
                </div>
            </CategoryHead>
            <FloatLR />
        </div>
        ) : (
                <div></div>
        )

    )
}
export default VideoId