function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
var ContextMenu = function () {
    function ContextMenu(player) {
        var _this = this;
        _classCallCheck(this, ContextMenu);
        this.player = player;
        this.shown = false;
        Array.prototype.slice.call(this.player.template.menuItem).forEach(function (item, index) {
            if (_this.player.options.contextmenu[index].click) {
                item.addEventListener('click', function () {
                    _this.player.options.contextmenu[index].click(_this.player);
                    _this.hide();
                });
            }
        });
        this.contextmenuHandler = function (e) {
            if (_this.shown) {
                _this.hide();
                return;
            }
            var event = e || window.event;
            event.preventDefault();
            var clientRect = _this.player.container.getBoundingClientRect();
            _this.show(event.clientX - clientRect.left, event.clientY - clientRect.top);
            _this.player.template.mask.addEventListener('click', function () {
                _this.hide();
            });
        };
        this.player.container.addEventListener('contextmenu', this.contextmenuHandler);
    }
    _createClass(ContextMenu, [
        {
            key: 'show',
            value: function show(x, y) {
                this.player.template.menu.classList.add('dplayer-menu-show');
                var clientRect = this.player.container.getBoundingClientRect();
                if (x + this.player.template.menu.offsetWidth >= clientRect.width) {
                    this.player.template.menu.style.right = clientRect.width - x + 'px';
                    this.player.template.menu.style.left = 'initial';
                } else {
                    this.player.template.menu.style.left = x + 'px';
                    this.player.template.menu.style.right = 'initial';
                }
                if (y + this.player.template.menu.offsetHeight >= clientRect.height) {
                    this.player.template.menu.style.bottom = clientRect.height - y + 'px';
                    this.player.template.menu.style.top = 'initial';
                } else {
                    this.player.template.menu.style.top = y + 'px';
                    this.player.template.menu.style.bottom = 'initial';
                }
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.shown = true;
                this.player.events.trigger('contextmenu_show');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.template.mask.classList.remove('dplayer-mask-show');
                this.player.template.menu.classList.remove('dplayer-menu-show');
                this.shown = false;
                this.player.events.trigger('contextmenu_hide');
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                this.player.container.removeEventListener('contextmenu', this.contextmenuHandler);
            }
        }
    ]);
    return ContextMenu;
}();
export default ContextMenu;