import { FC, useEffect, useMemo, useState, useCallback } from "react"
import Img from "@/components/img"
import useConfBase from "@/hooks/useConfBase"
import styles from "@/components/styles/relateList.module.css"
import useAxios from "@/hooks/useAxios"
import u, { ELocalKey } from "@/util"

const RelateList: FC = () => {
    const [config, updateConfBase,getData] = useConfBase();
    const [list, setList] = useState<any[]>([])
    const { req } = useAxios("index/statistics", 'post', true);
    
    useEffect(() => {
        if(!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === ''){
            // 在hooks里一直被使用，需要锁
        getData(4).then((res: any) => {
                                
                localStorage.setItem(ELocalKey._LOCK_FORMAT, '1'); 
                if (Array.isArray(res?.["maomiqixia"])) {
                    setList(res["maomiqixia"])
                }
            })
        }else{
            if(localStorage.getItem(ELocalKey._FORMAT)){
                const data =JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                if (Array.isArray(data?.["maomiqixia"])) {
                    setList(data["maomiqixia"])
                }
            }
        }
    }, [config])

    const hideOnNews = useMemo(() => {
        return window.location.href.indexOf("news") >= 0
    }, [])

    const clickStats = useCallback( async (x: any) => {
        req(u.getStatsParams(true, "youqingtujian", x?.name, window.location.pathname, false, true))
        return u.open(x.url)
    }, [])

    return (
        <div>
            {
                !hideOnNews && <>
                    <div className="s_h">友情推荐</div>
                    <div className={styles.r_l}>
                        {list.map((x: any, i: number) => {
                            return <div key={i} onClick={() => clickStats(x)}>
                                <Img src={x.img} height={50} width={50} getFullImgUrl/>
                                {x.name}
                            </div>
                        })}
                    </div>
                </>
            }
        </div>
    )
}
export default RelateList