import { PROD_SITE } from '@/const/prod.const';
import u from '@/util';
// export const appendHost = (
//     url: string, 
//     isNeedHost:  boolean = false,
//     host: string = '',
// ) => {
//     let finalReturnUrl = url;
//     if(url?.includes('maomi/mm_web_assets')){
//         finalReturnUrl = url;
//     }else{
//         finalReturnUrl = `/maomi/mm_web_assets/build${PROD_SITE}/${url}`;
//     }

//     if(isNeedHost){
//         finalReturnUrl = host + finalReturnUrl;
//     }

//     return finalReturnUrl;
// }

export const appendHost = (
    url: string, 
    domainSourceUrl: string = '', // Provide a default value
    withBuild: boolean = true,
    size: string = '50x50'
) => {
    // let imgDomain = `https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1/`; // Hardcoded domain
    let imgDomain = `${domainSourceUrl}`;

    // console.log("test img domain", imgDomain)

    if (!url) return "";

    // Ensure the URL does not start with the domain
    if (url.startsWith(imgDomain)) {
        // Remove the domain from the URL if it is already included
        url = url.replace(imgDomain, '');
    }

    // Avoid appending `.txt?size=50x50` multiple times
    if (!url.endsWith(`.txt?size=${size}`)) {
        if (url.endsWith('.txt')) {
            // If it ends with `.txt`, just add the query parameter
            url = `${url}?size=${size}`;
        } else {
            // If it does not end with `.txt`, append both `.txt` and the query parameter
            url = `${url}.txt?size=${size}`;
        }
    }

    // if(!url?.includes('maomi/mm_web_assets')){
    //     url = `/maomi/mm_web_assets/build${PROD_SITE}/${url}`;
    //     url = url.replace('//','/');
    // }

    if (!withBuild) {
        return `${imgDomain.replace(`/build${PROD_SITE}`, '')}${url}`;
    }

    // console.log(`withBuild loaded img - > ${imgDomain}${url}`);
    console.log('show image domain', imgDomain + url)
    return `${imgDomain}${url}`;
}

export const generateFullUrl = (
    url: string,
    mm_web_image_domain: string,
) => {
    let sourcurl = mm_web_image_domain ?? process.env.REACT_APP_STATIC_SOURCE_URL;

    if(!url?.includes('maomi/mm_web_assets')){
        url = `/maomi/mm_web_assets/build${PROD_SITE}/${url}`;
    }

    url = url?.replaceAll('//', '/');
    const vidKeyParam = u.addImgKeyParam(url);
    if(url?.includes(sourcurl)){
        return `${url}${vidKeyParam}`;
    }else{
        return `${sourcurl}${url}${vidKeyParam}`;
    }
}