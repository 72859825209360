import { FC, useCallback, useEffect, useState } from "react";
import styles from "@/components/styles/pagination.module.css"
import u from "@/util";

interface IPagination {
    changeHandler?: (row?: any) => void;
    total: number;
    perPage?: number
    lastPg: any;
    cancelFn?: any,
}

const Pagination: FC<IPagination> = ({ total, changeHandler, perPage = 50, lastPg, cancelFn }) => {
    const [params, setParams] = useState({
        current: 1,
        total
    })
    const [numb, setNumb] = useState<number[]>([]);
    // const totalAvailablePage = useMemo(() => Math.ceil(total / perPage), [perPage, total])

    const currentPgeLen = useCallback(() => {
        const batchSize = u.isMobile() ? 4 : 8
        const batchIndex = Math.ceil(params?.current / batchSize);
        const startIndex = (batchIndex - 1) * batchSize + 1;
        const endIndex = Math.min(batchIndex * batchSize, lastPg || 8);
        let nums = [] as number[]

        for (let i = startIndex; i < endIndex + 1; i++) {
            nums.push(i)
        }
        setNumb(nums)
    }, [params, lastPg])


    const updateParams = useCallback(async (passvalue: any,) => {
        const _val = { ...params, current: passvalue };
        if (params.current > 1) {
            cancelFn && cancelFn()
        }
        setParams(_val);
        changeHandler && changeHandler(_val);
        currentPgeLen()
    }, [params, changeHandler, currentPgeLen, cancelFn])

    useEffect(() => {
        currentPgeLen()
    }, [currentPgeLen])

    const nextHander = useCallback((mode: 0 | 1) => {
        if (mode === 0 && params.current <= 1) return;
        if (mode && params.current >= lastPg) return;
        const _val = mode ? params.current + 1 : params.current - 1;
        updateParams(_val)
    }, [params, updateParams])

    const setFirst = useCallback(() => {
        updateParams(1)
    }, [])

    const setLast = useCallback(() => {
        updateParams(lastPg)
    }, [lastPg])

    // useEffect(() => {
    //     fetchFn && fetchFn({ current: 1 })
    // }, [fetchFn])

    return (
        <>
            {total > 0 && <div className="fl align_center justify_center gap20 mb20">
                <div className="point" onClick={setFirst}>{`<<`}</div>
                <div className="point" onClick={() => nextHander(0)}>{`<`}</div>
                <div className="fl align_center justify_center gap10">
                    {numb.map((x, i) => {
                        return <div key={i}>
                            {
                                <div className={`${x == params.current ? styles.selected : styles.un}`} onClick={() => updateParams(x)}>
                                    {x}
                                </div>
                            }
                        </div>
                    })}
                </div>
                <div className="point" onClick={() => nextHander(1)}>{`>`}</div>
                <div className="point" onClick={setLast}>{`>>`}</div>
            </div>}
        </>

    )
}
export default Pagination