import useAxios from "@/hooks/useAxios";
import { FC, useCallback, useEffect } from "react";
import * as gl from "@/const/gl.const"

const External: FC = () => {
    const { req } = useAxios("click/info", "post", true)

    const checkIsApprove = useCallback( async (name: any, url: any) => {
        const res = req({
            name,
            url
        }) as any
        if (res?.data == 1) {
            window.location.href = gl.default.def_ins;
        } else {
            window.location.href = url;
        }
        setTimeout(() => {
            return window.location.href = url;
        }, 3000);
    }, [])

    useEffect(() => {
        const v = sessionStorage.getItem("z");
        const n = sessionStorage.getItem("n");
        checkIsApprove(v, n)
    }, [checkIsApprove])

    return (
        <div>
        </div>
    )
}
export default External;