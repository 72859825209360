import { FC, useMemo, useState } from "react"
import useAxios from "@/hooks/useAxios";
import { useCallback, useEffect } from "react";
import ImageList from "@/components/list";
import styles from "@/components/styles/layoutContent.module.css"
import u, { ELocalKey } from "@/util";
import useCategory from "@/hooks/useCategory";
import useConfBase from "@/hooks/useConfBase";
import NoticeModal from "@/components/modal/noticeModal";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import useFlipFlop from "@/hooks/useFlipFlop";

interface ILayoutContent {
    list: any[]
}

const LayoutContent: FC<ILayoutContent> = ({ list }) => {
    const { req } = useAxios("index/home", 'post',);
    const { req: reqNotice } = useAxios("notice/list", 'post',);
    const { req: reqStats } = useAxios("index/statistics", "post", true);
    const [data, setData] = useState<any>({})
    const [config] = useConfBase()
    const category = useCategory()
    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")

    const fetchVIPVideo = useCallback(async (config2 : any) => {
        const res = await req({ channel: "vip" })
        
        if (res?.data) {
            console.log(' home index --- ', res);
            setData(res?.data)
        }


        console.log('load config2 --- ', config2)
        if(!config2?.mm_web_image_domain) return;
        console.log('loaded config2 --- ', config2)

        const res2 = await reqNotice({site: '1', type:1, is_mobile: 0});
        console.log('notice---' , res2)
       
        try{
            if(res2 && res2?.data?.notice && Array.isArray(res2?.data?.notice) && res2?.data?.notice.length === 0){
                // no show
            }else{
                if(res2 && res2?.data?.notice && new Date().getHours().toString() !== localStorage.getItem(ELocalKey._NOTICE)){
                    localStorage.setItem(ELocalKey._NOTICE, new Date().getHours().toString());

                    let imgUrls: any = [];
                    if(res2?.data && res2?.data?.notice){
                        console.log('notice result---' , res2?.data?.notice)
                        if(res2?.data?.notice && res2?.data?.notice.length > 0){
                            res2?.data?.notice.map((noticeItem: any)=>{
                                if(noticeItem?.pic_1){
                                    imgUrls.push(`${noticeItem?.pic_1}`);
                                    // imgUrls.push(`${config2?.mm_web_image_domain}/${noticeItem?.pic_1}`.includes('.txt')?`${config2?.mm_web_image_domain}/${noticeItem?.pic_1}`:`${config2?.mm_web_image_domain}/${noticeItem?.pic_1}.txt`);
                                }
                            })
                        }
                    }
                    console.log(imgUrls, 'notice result imgUrls---' , config2 , "---",config2?.mm_web_image_domain )

                    modalSubject$.next({
                        id: EModalTypes.NOTICE,
                        data: {data: res2?.data?.notice,imgs: imgUrls},
                        event: () => flipHander(0)
                    })
                }
            }
        } catch(err) { }        
    }, [])

    useEffect(() => {
        fetchVIPVideo(config)
    }, [fetchVIPVideo, config])

    const handleMore = useCallback((row: any) => {
        hitStatis(row)

        try{
            if(window.location.href.includes("mmt=JDB2QGtySDdWMg==")){
                alert(row?.channel + '-' + u.isVip());
            }

            let __data = localStorage.getItem("_USER");
            if(__data){
                let userinfo = JSON.parse(u.decrypt(__data));
                if(userinfo && userinfo.username){
                    u.sendVIPLogDomain(userinfo.username, 'from main content - ' + row?.channel + '-' + u.isVip() + '[r] - ' + ((row?.channel === "vip" || row?.channel === "remen") && !u.isVip()));
                }
            }
        }catch(e){}

        if ((row?.channel === "vip" || row?.channel === "remen") && !u.isVip()) {
            return window.open("/user/buy")
        }
        if (row?.channel && row?._name) {
            if(row?.channel === 'topic'){
                return window.open(`/page/${row?.channel}/${row?.data[0]?.id}`)
            }
            const val = u.searchHomecategory(category, row?.channel, row?._name);
            return window.open(`/page/${row?.channel}/${val?.id}`)
        }
        
    }, [category,])

    const hitStatis = useCallback(async (x: any) => {
            const __val = u.getStatsParams(false, "home", x.channel + '-更多-' + x.realName, "") as any;
            console.log(__val , ' --- ', x )
            if (__val?.c && x?.count) {
                __val["c"] = __val["c"] + x?.count;
            }
            reqStats(__val)
    }, [])

    const _memoList = useMemo(() => {
        const _newdata = { ...data };
        delete _newdata["seo"]
        delete _newdata["notice"]
        _newdata["commen_list"] = { ..._newdata["commen_list"], "channel": "shipin" }
        console.log(' home vid list -', _newdata);
        console.log(' home vid list keys -', Object.keys(_newdata));
        console.log(' home vid list values -', Object.values(_newdata));
        return _newdata
    }, [data])

    // console.log(_memoList)

    return (
        <div>
            <NoticeModal/>
            <div className="mw1100">
                {_memoList && Object.values(_memoList).map((x: any, i) => {
                    return <div key={i}>
                        <div className={styles.main}>
                            <div className={styles.title}>{x.channel !== 'topic' ? name[i] : x.data[0]?.title}</div>
                            <div onClick={() => handleMore({ ...x, _name: x.channel !== 'topic' ? mapName[i] : x.data[0]?.title, realName: x.channel !== 'topic' ? name[i] : x.data[0]?.title })}>更多</div>
                        </div>
                        <ImageList is300={name[i] === '免费福利'} channel={x.channel} imgUrlkey="thumb" idkey="title" idValue="id" list={x.channel !== 'topic' ? x.data : x.data[0]?.list} isHome={true} topicParentId={x?.data && x?.data.length > 0 ? x?.data[0]?.id : 0}/>
                    </div>
                })}
            </div>
        </div>
    )
}
export default LayoutContent;

const name = ["VIP视频", "专题推荐1", "推荐视频", "专题推荐2","免费福利", "专题推荐3","中文字幕", "亚洲无码", "美女主播"]
const mapName = ["VIP 专区","专题推荐1", "短视频区", "专题推荐2","精彩动漫", "专题推荐3","中文字幕", "亚洲无码", "美女主播"]
// const name = ["VIP视频", "推荐视频", "免费福利", "中文字幕", "亚洲无码", "美女主播"]
// const mapName = ["VIP 专区", "短视频区", "精彩动漫", "中文字幕", "亚洲无码", "美女主播"]
