import u from '@/util';
import React, { useEffect } from 'react';

const useScrollToPosition = ()  => {

    useEffect(() => {
        setTimeout(() => { 
            if (u.isMobile() && u.hideAdsOnCertainPages() && 'scrollBehavior' in document.documentElement.style) {
                window.scrollTo({ top: 1075, behavior: true ? 'smooth' : 'auto' });
            } 
        }, 500)
    }, [])
};
export default useScrollToPosition