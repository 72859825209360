import { FC } from "react";
import Button from "@/components/button";
import styles from "@/components/styles/modal.module.css"
import useModal, { EModalTypes, IModal } from "@/hooks/useModal";
import u from "@/util";

const Modal: FC<IModal> = () => {
    const [modalData, closeModal] = useModal()

    return (
        modalData?.show && modalData?.id ===  EModalTypes.NORMAL && <div className={styles.common}>
            <div className={`${styles.body} cls_common`}>
                <div onClick={closeModal} className={styles.close}>X</div>
                <div className={styles.content}>
                    <div className={`${styles.content_title} mt20`}>温馨提示</div>
                    <div className={`${styles.content_inner_title} mt10`}>{modalData?.innerTitle}</div>
                    {modalData?.kefu && <div className={`${styles.kefu_blue} mt10`}>登录异常？请联系客服</div>}
                    {modalData?.btn && <div className="mt20">
                        <Button event={() => {
                            if (modalData?.kefu) {
                                return u.kefuOpen()
                            }
                            if(modalData?.event) {
                                modalData.event()
                            }
                            closeModal()
                        }} cls="btn_sty2">{modalData?.kefu ? '联系客服' : modalData?.data?.btnName || '我知道了'}</Button>
                    </div>}
                </div>
            </div>
        </div>
    )
}
export default Modal;