import u from "@/util";
import { FC, useMemo } from "react"
import styles from "./styles/commentlist.module.css"
import Button from "@/components/button";
import Img from "@/components/img";
import { appendHost } from "@/const/append";


interface ICommentlist {
    list: string[]
}

const commentList: FC<ICommentlist> = ({ list }) => {

    const totalComments = list ? list.length : 0;

    const formatDate = (created_at: string) => {
        // Extract the date part from the created_at string (YYYY-MM-DD)
        const datePart = created_at.split(' ')[0];

        // Get the current date in the format YYYY-MM-DD
        const today = new Date().toISOString().split('T')[0];

        // Check if the date part matches today's date
        return datePart === today ? "Today" : datePart;
    };

    return (
        <div>
            <div style={{display:'flex', justifyContent:'space-between', paddingBottom:'20px'}}>
                <div style={{color:'#666666', fontWeight:'500', fontSize:'20px', alignItems:'center'}}>
                评论（{totalComments}）
                    </div>
                    <div style={{backgroundColor:'#FF8E9D', color:'white', paddingBlock:'10px', paddingInline:'50px', borderRadius:'10px', fontSize:'16px', cursor:'pointer'}}>
                        立即评论
                    </div>
            </div>
                                    {list ? (
                                        list.map((comment: any, index: number) => {
                                            return <div key={index} className={styles.imgdiv} >
                                                <div className={styles.inner} style={{ paddingTop: '20px', fontSize:'16px', alignItems:'center', width:'95%'}}>
                                                <div style={{color:'#000000', fontWeight:'600' }}>
                                                {comment.member_obj.nickname}
                                                    </div>

                                                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:'center', cursor:'pointer'}}>
                                                    <div style={{fontSize:'12px', fontWeight:'400', color:'#707070', paddingInline:'25px'}}>
                                                    {formatDate(comment.created_at)}
                                                      </div>
                                                    <Img src={appendHost("/images/icon/comment.png")} width={30} height={30} cls={``} />

                                                        
                                                    </div>
                                                </div>
                                                <div style={{fontSize:'14px', paddingBlock:'15px'}}>
                                                {comment.content}
                                                </div>
                                                <hr style={{border:'1px solid', color:'#666666'}}/>
                                            </div>
                                        })) : (
                                        <div style={{display:'flex', placeContent:'center'}}> 暂无评论 </div>
                                    )}
        </div>
    )
}
export default commentList