import { FC, useCallback, useEffect, useState } from "react"

interface ISelect {
    value?: string;
    list: { name: string, value: string }[],
    onChange: (value: any) => void;
    loader?: (x: any) => any;
}

const Select: FC<ISelect> = ({ value, list, onChange, loader }) => {
    // const [select, setSelect] = useState<any>(null);
    const [newList, setNewList] = useState<any>([])

    useEffect(() => {
        if (loader) {
            const _list = list.map(loader)
            return setNewList(_list)
        }
        setNewList(list)
    }, [list, loader])

    useEffect(() => {
        if (Array.isArray(newList) && newList.length > 0) {
            onChange(newList[0].value)
        }
    }, [newList])

    const onChangeWrapper = useCallback((event: any) => {
        var select = event.target;
        var selectedOption = select.options[select.selectedIndex];
        onChange && onChange(selectedOption.value)
    }, [])

    return <select onChange={onChangeWrapper}>
        {newList.map((x: any, i: number) => {
            return <option key={i} defaultValue={value == x.value?  x.value : "" } value={x.value}>{x.name}</option>
        })}
    </select>
}
export default Select