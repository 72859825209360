import u from "@/util";
import { FC, useMemo } from "react"

const Download: FC = () => {
    window.location.href = "download/android";
    if(u.isMobile()){
        // window.location.href = "download/mobile";
        // window.location.href = "mobile.html";
    }else{
        // window.location.href = "download/pc";
        // window.location.href = "pc.html";
    }

    return <div>
        Loading...
    </div>
}
export default Download;