import { useState, useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import u, { ELocalKey } from '@/util';

export const userInfo$ = new BehaviorSubject({});

const useUser = () => {
    const [state, setState] = useState<any>();

    const localData = useMemo(() => {
        try {
            const __currentData = Object.keys(state);
            if (__currentData.length > 0) {
                return state
            }
            const __data = localStorage.getItem(ELocalKey._USER);
            return JSON.parse(u.decrypt(__data))
        } catch (error) {
            return {}
        }
    }, [state])

    useEffect(() => {
        const subscription = userInfo$.subscribe(setState);
        return () => subscription.unsubscribe();
    }, []);

    const updateState = (val: any) => {
        localStorage.setItem(ELocalKey._USER, u.encrypt(JSON.stringify(val.data)))
        userInfo$.next(val.data)
    };

    return [localData, updateState];
}
export default useUser;