import { FC } from "react"
import Img from "./img";
import styles from "@/components/styles/backBlack.module.css"
import u from "@/util";
// import { useNavigate } from "react-router-dom";

interface IBackBlack {
    title: any;
    row?: any;
    hideContent?: boolean,
    channel?: any
}

const BackBlack: FC<IBackBlack> = ({ title, row, hideContent = false, channel }) => {
    // const navigate = useNavigate();
    return (
        <div>
            <div className={`fl ${styles.main}`}>
                <div className="fl gap20 align_center">
                    <div onClick={() => u.back()}>
                        <Img width={25} height={25} src={bimg} />
                    </div>

                    <div className={styles.tit}>{title || '-'}</div>
                </div>

                {typeof channel === 'undefined' && !hideContent && <>
                    <div className={styles.maintitle}>{row?.chapters?.data?.length > 0 ? row?.chapters?.data[0].title : "-"}</div>
                    <div className={styles.white}>共 <span className={styles.ylw}>{row?.chapters?.data.length}</span> 章节</div>
                    <div className={styles.white}>{row?.finish ? '已完结' : '正在连载中'}</div>
                    <div className={styles.tit}>更新时间：{u.toFmt(row?.insert_time * 1000)}</div>
                </>}

                {
                    channel === "meinv" && <div className="grid gap10">
                        <div className={styles.mv_title}>{row?.data?.[0]?.tags}</div>
                        <div className={styles.mv_nc}>
                            <div>共<span className={styles.mv_in}>{row?.data?.length || 0}期</span></div>
                            <div>
                                {u.toFmt(row?.data?.[0]?.update_time * 1000)}
                            </div>
                        </div>
                    </div>
                }
                {
                    channel === "detailmeinv" && <div className="grid gap10">
                    <div className={styles.mv_title}>{row?.title}</div>
                    <div className={styles.mv_nc}>
                        <div>共<span className={styles.mv_in}>{row?.content?.length || 0}期</span></div>
                        <div>
                            {u.toFmt(row?.insert_time * 1000)}
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}
export default BackBlack;

const bimg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAdBJREFUWEftlr9LVmEYhq+bBoeoXBpqyyX/gBSkqcVqsFFTm3NQxLFBSQQHp4h+QZGWig3hKgQhjhGIu4KCs2GQS0u3vPB8cIbwO7/8vsWznfecw3Wd+3necx7R5kNt5nMhUHsCtnuAl8Bf4JGkP2eVuVYB2w+AReBGQO9J2mqJgO1R4CPQEcBpSfPNmryWBGxPRuwN3oSkN83g6XplAduzwPMMbFTSWh54ZQHbr4CJgP0GEnwjL7y0gO1LwAowHLADYETSjyLwUgK2rwOrQH/AdoDHknaLwgsL2O6ON78TsE1gSNJRGXghAdt3A34rYOvAoKR/ZeG5BWwPROxXA/ZB0tMq4MazTbeh7SvAIdAZDy1IelYHPFcCti8DqctT86WjtQKJGCVI2+5aSLyXNFZHCk1L0IDY7os+6Iq1r7H9zr8JMxK3gWWgN9a+h8SvsmnkTiAjkXrhM/Aw1rbTF1HSXhmJwgLRE+lT/Al4EtD9kPhZVKKUQCaNF8BUnB+HxLciEpUEIo0ZYC4DTT+lL3klKguExDjwOgMdl/Q2j0QtAiGRfs1LbRnJMj1xP4bSm7HWuqE0I5HG8ndAGscHJJ2cVYraSpCn3v+750Kg7QmcAqqDhCHrft87AAAAAElFTkSuQmCC`