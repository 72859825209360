export const generateQRCode = async (text: string): Promise<string> => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) throw new Error("Canvas is not supported in this browser.");

    // Use a public QR code API to fetch the QR code
    const apiUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
        text
    )}&size=200x200`;

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = apiUrl;

    return new Promise((resolve, reject) => {
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const dataUrl = canvas.toDataURL("image/png");
            resolve(dataUrl); // Return the generated QR code as a data URL
        };
        img.onerror = () => reject("Failed to load QR code image.");
    });
};