import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Img from "./img";
import { appendHost } from "@/const/append";

import style from "@/components/styles/header.module.css";
import { Link, useNavigate } from "react-router-dom";
import useUser from "@/hooks/useUser";
import useAxios from "@/hooks/useAxios";
import VipCard, { vipCardSubject$ } from "@/components/vipCard";
import useConfBase from "@/hooks/useConfBase";
import u, { ELocalKey, EVIPtype, teseConstant } from "@/util";
import useWindowLoadCallback from "@/hooks/useWindowEffect";
import useCategory from "@/hooks/useCategory";
import useWindowDimensions from "@/hooks/useDimension";
import { categpryImg } from "./category";
import Back from "./back";

interface IHeader {
    isScroll: boolean;
    hideHead?: boolean
}

const Header: FC<IHeader> = ({ isScroll, hideHead }) => {
    const [config, updateConfBase, getData] = useConfBase();
    const [userInfo] = useUser();
    const [selected, setSelected] = useState<any>(0);
    const [clickVipCard, setVipCard] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false)
    const [downloadUrl, setDownloadUrl] = useState<string>("");
    const [dlist, setDlist] = useState<any>({})
    const { req } = useAxios("index/statistics", "post", true);
    const { req: reqUnseenCount } = useAxios("message/unseenCount", "post");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [caterList, setCaterList] = useState<any[]>([])
    const [defData, setDefData] = useState<any>([])
    const [zhutiData, setZhutiData] = useState<any>([])
    const [zhuti2Data, setZhuti2Data] = useState<any>([])
    const category = useCategory()
    const { width } = useWindowDimensions();
    const navigate = useNavigate;
    const [indexShowSubmenu, setIndexShowSubmenu] = useState<any>(-1)
    const [msgAmt, setMsgAmt] = useState<any>(0)
    const [list, setMenuList] = useState<boolean>(false)
    const [encryptUrls, setEncryptUrls] = useState<string[]>([])



    const handleIndexSubMenu = (index: number) => {
        if (indexShowSubmenu === index) {
            setIndexShowSubmenu(-1);
            return;
        }
        setIndexShowSubmenu(index);
    };

    const handleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const cilihandler = useCallback(async (type: any, realName: any, channel: any) => {
        const __val = u.getStatsParams(false, "", "", window.location.pathname);
        // req({ ...__val, category: "site", name: type ? "pic" : "cili" });
        let categoryToSend = type ? channel : "cili"
        req({ ...__val, category: categoryToSend, name: realName });

        if (process.env.NODE_ENV === "production") {
            const val = type ? "pic." : "download."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            return u.open(`https://${urlary}`)
        }
        return u.open(type ? config?.pic_url : config?.cili_url)
    }, [])

    const goHitAllStats = useCallback(async (val: any, jumpUrl?: string) => {
        console.log(val)
        const __val = u.getStatsParams(false, val?.channel, val?.name, window.location.pathname,) as any;
        if (typeof val?.index === "number" && __val?.c) {
            __val["c"] = __val["c"] + val?.index + 1
        }
        req({ ...__val });
        // const token = u.getLocalData(ELocalKey.__QTKN, true);
        // if (!token) return;
        // const __tqknParams = {
        //     token,
        //     project_id: ProjectInfo.ID,
        //     site: __val.site,
        //     page: __val?.name,
        //     category: val?.channel,
        //     type: "link" as any,
        //     domain: __val.domain
        // }
        // R.request(postQesStats(__tqknParams), false, false, 1);
        if (jumpUrl) {
            return u.open(jumpUrl)
        }
    }, [])

    const handleAuthVIP = useCallback(async (val: any, type?: any, index?: any) => {
        console.log('site_cili', config?.site_cili)
        console.log('val', val)
        console.log('type', type)

        if (val.channel === 'topic') {
            return goHitAllStats(val, val.url)
        }
        // xiaoshuo add novel.
        if (val.channel === 'xiaoshuo' && config?.site_xiaoshuo && config?.site_xiaoshuo === '1' && process.env.NODE_ENV === "production") {
            const val = "novel."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            await goHitAllStats(val)
            return u.open(`https://${urlary}`)
        }

        if ((val.id === 149 || val.id === 158 || val.id === 159) && val.channel === 'remen') {
            return; // temp unable click
        }

        if (config?.site_cili == 1) {
            if (val.channel == 'cili') {
                cilihandler(0, val.name, val.channel);
                return;
            } else if (val.channel == 'meinv' || val.channel == 'tupian') {
                cilihandler(1, val.name, val.channel);
                return;
            }
        }
        // 概率跳转
        const isMatch = Math.floor(Math.random() * 4) === 3;
        const res = u.percentJump(val?.id);
        if (isMatch && res) {
            return goHitAllStats(val, res)
        }
        if (val?.exception) {
            // 根据拿到的id判断是否要跳到tese页面
            await goHitAllStats(val)
            if (typeof val?.actualId === 'number' && val?.actualId > 0) {
                return window.location.href = `/page/tese/${val?.actualId}` // u.open(`/page/tese/${val?.actualId}`)
            }
            return u.open(val?.url)
        }
        if (val?.channel && val?.id) {
            goHitAllStats(val)
            if (/^(http|https):\/\//.test(val?.url)) {
                sessionStorage.setItem("z", val?.name)
                sessionStorage.setItem("n", val?.url)
                return u.open("/external")
            }
            // cancelAllRequests()
            return u.mapCategoryRoute(navigate, type, val.channel, val.id, userInfo?.token)
        }
    }, [userInfo, navigate])

    useEffect(() => {
        const intervalQX = setInterval(qxData, 1000);

        function qxData() {
            const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
            // console.log('qxData run this', __data)
            if (Array.isArray(__data?.["zhuanti"])) {
                setZhutiData(__data["zhuanti"])
            }

            if (__data?.["zhuanti2"] && Array.isArray(__data?.["zhuanti2"])) {
                setZhuti2Data(__data["zhuanti2"])
            }
            // console.log('qxData', zhutiData)
            // console.log('qxData2', zhuti2Data)
            if (__data) {
                stopQX();
            }
        }

        function stopQX() {
            console.log('stopQX header', zhutiData)
            clearInterval(intervalQX);
        }
        // u.timeoutCb(() => {
        //     const __data  = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
        //     console.log('run this' , __data)
        //     if (Array.isArray(__data?.["zhuanti"])) {
        //         setZhutiData(__data["zhuanti"])
        //     }
        // });

        if (!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === '') {
            // 在hooks里一直被使用，需要锁
            getData(4).then((res: any) => {
                localStorage.setItem(ELocalKey._LOCK_FORMAT, '1');
                if (Array.isArray(res?.["tesezhuanqu"]?.[0]?.["child"])) {
                    setDefData(res["tesezhuanqu"][0]["child"])
                }

                if (Array.isArray(res?.["zhuanti"])) {
                    setZhutiData(res["zhuanti"])
                }

                if (res?.["zhuanti2"] && Array.isArray(res?.["zhuanti2"])) {
                    setZhuti2Data(res["zhuanti2"])
                }
            })
        } else {
            if (localStorage.getItem(ELocalKey._FORMAT)) {
                const data = JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                if (Array.isArray(data?.["tesezhuanqu"]?.[0]?.["child"])) {
                    setDefData(data["tesezhuanqu"][0]["child"])
                }

                try {
                    if (Array.isArray(data?.["zhuanti"])) {
                        setZhutiData(data["zhuanti"])
                    }

                    if (data?.["zhuanti2"] && Array.isArray(data?.["zhuanti2"])) {
                        setZhuti2Data(data["zhuanti2"])
                    }
                } catch (err) { }
            }
        }
    }, [config])

    useEffect(() => {
        if (defData && defData.length === 0) {
            setDefData(teseConstant)
        }
        const _list = [...category];
        console.log(defData, 'sidebar')
        console.log(zhutiData, 'zhutiData')
        console.log(zhuti2Data, 'zhuti2Data')

        // def Data
        const __ext = defData.map((x: any) => ({
            ...x,
            name: x?.name,
            url: x.url,
            id: 1,
            channel: "tesezhuanqu",
            exception: true,
            actualId: x?.provider_id || 0
        }))
        const _val = { icon: categpryImg[1], name: "特色专区", data: __ext }

        // zhutiData
        //  const __ext2 = zhutiData.map((x: any) => ({
        //     ...x,
        //     name: x?.name,
        //     url: "/page/topic/" + x.id,
        //     id: x.id,
        //     channel: "topic",
        //     exception: true,
        //     actualId: x?.provider_id || 0
        // }))

        // const _val2 = { icon: categpryImg[0], name: "热销专题", data: __ext2, url: "/topic", channel: "topic" }
        // console.log('zhaunti' , _val2);

        // zhuti2Data
        //    const __ext3 = zhuti2Data.map((x: any) => ({
        //     ...x,
        //     name: x?.name,
        //     url: "/page/topic/" + x.id,
        //     id: x.id,
        //     channel: "topic",
        //     exception: true,
        //     actualId: x?.provider_id || 0
        // }))

        // const _val3 = { icon: categpryImg[0], name: "限时专题", data: __ext3, url: "/topic", channel: "topic" }
        // console.log('zhaunti' , _val3);

        if (width < 600) {
            _list.push(_val)
            // _list.splice(1, 0, _val)
            // _list.splice(1, 0, _val3)
            // _list.splice(1, 0, _val2)
            console.log('_list push head 1', _list);
        } else {
            _list.push(_val)
            // _list.splice(1, 0, _val)
            // _list.splice(1, 0, _val3)
            // _list.splice(1, 0, _val2)
            console.log('_list push head 2', _list);
        }
        return setCaterList(_list)
    }, [category, defData, zhutiData, zhuti2Data, width])

    useEffect(() => {
        if (caterList && Array.isArray(caterList)) {
            // const imgurl = caterList?.map((x: any) => `${config?.mm_web_image_domain}/${x.tags_image}.txt`);
            const imgurl = caterList?.map((x: any) => `${x.tags_image}`);

            setEncryptUrls(imgurl)
        }
    }, [caterList])

    const filterCater = useCallback((data: any[]) => {
        const normalHideIds = [66]
        // const mustHideIds = [183] // topic id
        // data = data?.filter((x) => !mustHideIds.includes(x.id))

        return data.filter((x) => !normalHideIds.includes(x.id) && !normalHideIds.includes(x.parent_id))
    }, [])

    const fetchMsgAmt = useCallback(async () => {
        if (!userInfo?.token) return;
        try{
            if(localStorage.getItem(ELocalKey._MSGUNSEEN) && 
            (localStorage.getItem(ELocalKey._NOTICE) && new Date().getHours().toString() === localStorage.getItem(ELocalKey._NOTICE))
            ){
                setMsgAmt(parseInt(localStorage.getItem(ELocalKey._MSGUNSEEN) ?? "0"))
            }else{
                const res = await reqUnseenCount({
                    token: userInfo?.token
                });
        
                console.log('reqUnseenCount message  ', res)
                if (res) {
                    setMsgAmt(res?.data?.unseen_count)
                    localStorage.setItem(ELocalKey._MSGUNSEEN, res?.data?.unseen_count);
                }
            }
        }catch(err){
            setMsgAmt(0)
        }
    }, [userInfo])


    useEffect(() => {
        fetchMsgAmt()
    }, [fetchMsgAmt])
    // useEffect(() => {
    //     getData(1).then((res: any) => {
    //         if (res?.maomiapk?.url) {
    //             setDownloadUrl(res?.maomiapk?.url)
    //         }
    //     })
    // }, [getData, config])

    // useWindowLoadCallback(() => {
    //     const res = u.getScriptVal(1) as any;
    //     if (res?.maomiapk?.url) {
    //         setDownloadUrl(res?.maomiapk?.url);
    //     }
    // })

    useEffect(() => {
        u.timeoutCb(() => {
            const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__MM));
            if (__data && __data?.maomiapk?.url) {
                setDownloadUrl(__data.maomiapk.url);
            }
        })
    }, [])

    useEffect(() => {
        if (!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === '') {
            // 在hooks里一直被使用，需要锁
            getData(4).then((res: any) => {
                if (res) {
                    localStorage.setItem(ELocalKey._LOCK_FORMAT, '1');
                    setDlist(res)
                }
            })
        } else {
            if (localStorage.getItem(ELocalKey._FORMAT)) {
                const data = JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                setDlist(data)
            }
        }
    }, [getData])

    const hitStats = useCallback(async (x: any) => {
        req(u.getStatsParams(true, x?.channel, `${x?.symbol}${x?.name}`, x?.name, x?.symbol === "yq_")) // header bar
        if (typeof x?.provider_id === "number" && x?.provider_id > 0) {
            return u.open(`/page/tese/${x?.provider_id}`)
        } else {
            sessionStorage.setItem("z", x.name)
            sessionStorage.setItem("n", x.url)
            u.open("/external")
        }
    }, [])

    const hitStats1 = useCallback(async (x: any,) => {
        if (x?.url) {
            const __val = u.getStatsParams(true, x?.channel, x?.name, x?.url) as any;

            if (__val?.c && x?.count) {
                __val["c"] = __val["c"] + x?.count;
            }
            req(__val)
            return u.open(x.url)
        }
    }, [])


    const hitStats2 = useCallback(async () => {
        // if (x?.url) {
        const __val = u.getStatsParams(false, "news", "每日发现", "") as any;

        // if (__val?.c && x?.count) {
        //     __val["c"] = __val["c"] + x?.count;
        // }
        req(__val)

        const val = "luntan."
        let urlary = window.location.origin.split("https://") as any;
        if (urlary.length === 1) {
            urlary = window.location.origin.split("http://") as any;
        }
        if (urlary.length === 2) {
            urlary[0] = val;
        }
        urlary = urlary.join("").replace("www.", "");
        return u.open(`https://${urlary}/bbs`);
        // return u.open("/news")
        // }
    }, [])


    const clickDl = useCallback(async () => {
        window.open('/download');
        // const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__MM));
        // if (__data && __data?.maomiapk?.url) {
        //     window.open(__data.maomiapk.url)
        // }
    }, [])


    const cssStyle = useMemo(() => {
        const _style = `fl ${style.header} align_center justify_center ${u.themeHeader()}`
        return isScroll ? `${_style} ${style.scroll}` : _style
    }, [isScroll])

    const menuHandler = useCallback(
        async (type: string) => {
            const __val = u.getStatsParams(false, "", "", window.location.pathname);

            let categoryToSend;
            let name;

            // Set the category and name based on the type
            switch (type) {
                case "novel":
                    categoryToSend = "novel";
                    name = "成人小说";
                    break;
                case "pic":
                    categoryToSend = "pic";
                    name = "写真图库";
                    break;
                case "download":
                    categoryToSend = "cili";
                    name = "磁力资源";
                    break;
                case "luntan":
                    categoryToSend = "luntan";
                    name = "猫咪论坛";
                    break;
                default:
                    return;
            }

            // Request parameters
            req({ ...__val, category: categoryToSend, name });

            // Handle URL based on environment
            if (process.env.NODE_ENV === "production") {
                const prefix = `${type}.`; // "novel.", "pic.", "download.", or "luntan."
                let urlary = window.location.origin.split("https://") as any;
                if (urlary.length === 1) {
                    urlary = window.location.origin.split("http://") as any;
                }
                if (urlary.length === 2) {
                    urlary[0] = prefix;
                }
                urlary = urlary.join("").replace("www.", "");
                return u.open(`https://${urlary}`);
            }

            // Fallback URLs for development environment
            switch (type) {
                case "novel":
                    return u.open(config?.novel_url);
                case "pic":
                    return u.open(config?.pic_url);
                case "download":
                    return u.open(config?.cili_url);
                case "luntan":
                    return u.open(config?.luntan_url);
            }
        },
        []
    );

    const handleKf = () => {
        if (u.isLogin()) {
            return window.open(userInfo?.custom_url)
        }
        return u.formLogin()
    }


    return (
        <div className={`relative ${hideHead ? 'hideh' : ''}`}>
            <div className={cssStyle}>
                <div className={`grid ${style.inner} align_center`}>
                    <div className={`fl gap10 align_ceter ${style.lg1}`}>
                        {u.isMobile() &&
                            <div onClick={handleSidebar}>
                                <Img height={17} width={23} src={appendHost("/images/header/menu_burger.png")} />
                            </div>
                        }
                        <Link to="/home">
                            <Img cls={style.logo_1} width={130} height={25} src={appendHost("/images/header/logo1.png")} />
                        </Link>
                        <div className={style.lct}>
                            {window.location.host}
                        </div>
                    </div>
                    <div className={`fl align_center gap10 mauto ${style.lg2}`}>
                        <div className="fl gap5 align_center">
                            <Img height={15} width={15} src={appendHost("/images/header/menu_home.png")} />
                            <Link className="a_w" to="/home">
                                <div>首页</div>
                            </Link>
                        </div>
                        <div>/</div>
                        <div className="fl gap5 align_center">
                            <Img height={15} width={15} src={appendHost("/images/header/menu_new.png")} />
                            <div className="point" onClick={() => hitStats2()}>每日发现</div>
                        </div>
                    </div>
                    <div className={`fl align_center ${style.ti_con}`}>
                        {topSectionList(dlist).map((x, i) => {
                            return <div key={i} className={`fl point gap5 relative ${style.h40} ${selected === i && selected >= 3 ? style.bl : ''}`} onMouseEnter={() => setSelected(i)} onMouseLeave={() => setSelected(0)} >
                                <div className={style.lh54} onClick={() => {
                                    hitStats1({ ...x, index: i })
                                }}>
                                    {x.name}
                                </div>
                                <div className={style.mt25}>
                                    {Array.isArray(x.child) && x.child.length > 0 && <span className={style.dropd}></span>}
                                </div>
                                {selected === i && selected >= 3 && <div className={`abs ${style.top_item}`}>
                                    <div onMouseLeave={() => setSelected(0)}>
                                        {x.child?.map((t: any, i: number) => {
                                            return <div key={i} onClick={() => hitStats({ ...t, symbol: x?.code })}>
                                                {t.name}
                                            </div>
                                        })}
                                    </div>
                                </div>}
                            </div>
                        })}
                    </div>
                    <div className={style.h_btm_con}>
                        <div onClick={() => setShow(!show)} className={`${style.lg3} relative`}>
                            <div>
                                {!!msgAmt && msgAmt > 0 && <>
                                    <div className={style.msgamt}>{msgAmt}</div>
                                </>}
                                <Img src={appendHost("/images/header/user_logo.png")} width={40} height={28} />
                            </div>
                            {show && <div className={`abs ${style.ho_m}`}>
                                <div className={style.line} >
                                    <Img src={appendHost("/images/header/icon_avatar.png")} width={20} height={20} />
                                    <div>
                                        {userInfo?.token ? <div onClick={() => window.open("/user/info")}> {userInfo?.username} </div> : <>
                                            <span onClick={() => u.formLogin()}>登录</span>｜<span onClick={() => u.formRegister()}>注册</span>
                                        </>}
                                    </div>
                                </div>
                                <div className={style.line} onClick={() => setVipCard(true)}>
                                    <Img
                                        cls=""
                                        width={20}
                                        height={20}
                                        src={appendHost("/images/menu/vip_badge.png")}
                                    />
                                    <div>VIP 福利</div>
                                </div>
                                <div className={style.line}>
                                    <Img
                                        cls=""
                                        src={appendHost("/images/menu/app_tele.png")}
                                        width={20}
                                        height={20}

                                    />
                                    <div>
                                        <a className="a_b" href="https://t.me/maomiresource" style={{ color: '#fff' }}>猫友开车群</a></div>
                                </div>
                                <div className={style.line} onClick={() => clickDl()}>
                                    <Img
                                        cls=""
                                        src={appendHost("/images/menu/app_down.png")}
                                        width={20}
                                        height={20}

                                    />
                                    <div>APP下载</div>
                                </div>
                            </div>}
                        </div>
                        <div className={`fl align_center justify_end gap10 ${style.lg3_t}`}>
                            <div className="mt10" onClick={() => clickDl()}>
                                <Img height={30} width={70} src={appendHost("/images/header/app_btn.png")} />
                            </div>
                            <div className="mt10" onClick={() => window.open("/user/info")}>
                                <Img height={20} width={20} src={appendHost("/images/header/icon_vip.png")} />
                            </div>
                            <Link className="a_w mt9" to="/user/info" style={{ position: 'relative' }}>
                                <Img height={20} width={20} src={appendHost("/images/header/icon_avatar.png")} />
                                {!!msgAmt && msgAmt > 0 && <>
                                    <div className={style.msgamtdesktop}>{msgAmt}</div>
                                </>}
                            </Link>
                            {userInfo?.token ?
                                <div className="fz12 point text_over">
                                    <Link className="a_w" to="/user/info">
                                        {userInfo?.username}
                                    </Link>
                                </div>
                                :
                                <div className="fl align_center fz12 gap10 ">
                                    <Link className="a_w" to={u.formLogin(true)}>登录</Link>
                                    <div>|</div>
                                    <Link className="a_w" to={u.formRegister(true)}>注册</Link>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {clickVipCard && <VipCard closeEvent={() => {
                setVipCard(false);
                vipCardSubject$.next(true)
            }} isMobile />}

            {sidebarOpen && <>
                <div id="mySidenav" className="sidenav" >
                    <div className="sideclose" onClick={handleSidebar}>

                    </div>
                    <div className="sidecontent">
                        <div style={{ background: '#F893B9', height: '160px' }}>
                            <div className="sidelogo">
                                {/* <Back cls={'mt28 pinline10'} useWhiteIcon onClick={handleSidebar}></Back> */}
                                <div onClick={handleSidebar}>
                                    <Img
                                        cls=""
                                        src={appendHost("/images/icon/arrow-left-1.png")}
                                        width={20}
                                        height={20}
                                        style={{ paddingInline: '10px' }}
                                    />

                                </div>

                                <Img width={100} height={20} src={appendHost("/images/header/logo1.png")} />
                                <div className={style.menuline} onClick={() => clickDl()}>
                                    <Img
                                        cls=""
                                        src={appendHost("/images/menu/app_down.png")}
                                        width={15}
                                        height={15}
                                    />
                                    <div style={{ color: '#fff', fontWeight: '400', fontSize: '12px' }}>APP下载</div>
                                </div>
                            </div>
                            <div className={style.loginoutline} >
                                <Img
                                    cls=""
                                    src={appendHost("/images/menu/user_profile.png")}
                                    width={60}
                                    height={60}
                                />
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                        {userInfo?.token ? <div onClick={() => window.open("/user/info")} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> {userInfo?.username}

                                            {!u.isVip() && <>
                                                <div style={{ background: '#FFC700', borderRadius: '20px', color: '#575757', paddingBlock: '8px', paddingInline: '12px', fontWeight: '600' }}>
                                                    开通VIP
                                                </div>
                                            </>}
                                        </div> : <>
                                            <span onClick={() => u.formRegister()}>注册</span>/<span onClick={() => u.formLogin()}>登入</span>

                                        </>}


                                    </div>
                                    {u.isVip() && <>
                                        <div className={style.vipline} style={{ paddingTop: '3px', alignItems: 'center' }}>
                                        <Img width={15} height={15} src={userInfo?.is_vip === '2' ? appendHost("/images/menu/vip_badge.png") : userInfo?.is_vip === '1' ? appendHost("/images/menu/vip_badge.png") : appendHost("/images/menu/non_vip_badge.png")} style={{ paddingTop: '4px' }} />
                                        <div className="c1 fl" style={{ color: '#fff' }}>{userInfo?.is_vip === '2' ? '永久VIP用户' : userInfo?.is_vip === '1' ? 'VIP用户' : '您还不是猫咪VIP会员'}
                                            {userInfo?.is_vip === '1' && <>
                                                ({userInfo.val_time !== '0' && <div className="c1">VIP会员 {u.toFmt(userInfo.val_time * 1000)} 天后到期</div>})
                                            </>}
                                        </div>
                                        {(userInfo?.is_vip === '1' || userInfo?.is_vip === '2') && (
                                            <div onClick={() => setMenuList(!list)}>
                                                {list ? (
                                                    <div>
                                                        <Img
                                                            cls=""
                                                            src={appendHost("/images/icon/arrow-up.png")}
                                                            width={20}
                                                            height={20}

                                                        />                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Img
                                                            cls=""
                                                            src={appendHost("/images/icon/arrow_down.png")}
                                                            width={13}
                                                            height={13}

                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                    </>}


                                </div>


                            </div>
                        </div>

                        {list && <>
                            <div className="mainmenu1" onClick={() => setVipCard(true)}>
                                <div className="mainmenucontent" style={{ display: 'flex', fontSize: '16px', fontWeight: '500', color: '#7A7A7A' }} >
                                    <Img
                                        cls=""
                                        src={appendHost("/images/menu/new_home_icon_vip.png")}
                                        width={23}
                                        height={23}

                                    />
                                    VIP 福利
                                </div>

                            </div>
                            <div className="mainmenu1" onClick={() => hitStats2()}>
                                <div className="mainmenucontent" style={{ display: 'flex', fontSize: '16px', fontWeight: '500', color: '#7A7A7A' }} >

                                    <Img
                                        cls=""
                                        src={appendHost("/images/menu/new_home_icon_tele.png")}
                                        width={23}
                                        height={23}

                                    />
                                    <a href="https://t.me/maomiresource" style={{ textDecoration: 'none', outline: '0 none', fontSize: '16px', fontWeight: '500', color: '#7A7A7A' }}>猫友开车群</a></div>

                            </div>
                            <div className="mainmenu1" onClick={() => clickDl()}>
                                <div className="mainmenucontent" style={{ display: 'flex', fontSize: '16px', fontWeight: '500', color: '#7A7A7A' }}>
                                    <Img
                                        cls=""
                                        src={appendHost("/images/menu/new_home_icon_app.png")}
                                        width={23}
                                        height={23}

                                    />
                                    APP下载
                                </div>

                            </div>
                        </>

                        }

                        {/* <div className="mainmenu" onClick={() => hitStats2()}>
                            <div className="mainmenucontent" style={{ display: 'flex', fontSize: '16px', fontWeight: '500', color: '#868686' }} >

                                <Img
                                    cls=""
                                    encryptUrl={"/images/menu/new_home_icon_meirifaxian.png"}
                                    src={""}
                                    width={23}
                                    height={23}

                                />
                                每日发现
                            </div>

                            <div style={{ display: 'flex', width: '50%', placeContent: 'flex-end', color: '#868686', fontSize: '12px', fontWeight: '400', gap: '10px', alignItems: 'center' }}>

                                色圈最新资讯
                            </div>
                        </div> */}

                        {filterCater(caterList).map((x: any, i: number) => {
                            return <>
                                <div className="mainmenu">
                                    <div className="mainmenucontent" style={{ display: 'flex' }} onClick={() => handleAuthVIP({ ...x, index: -1 }, 1, i)}>
                                        {x.name === '在线电影' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[5])}
                                                width={23}
                                                height={23}

                                            />
                                        </>}
                                        {x.name === '情色小说' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[6])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.name === '有声小说' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[7])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.name === '撸撸图区' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[1])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.name === '激情图区' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[4])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.name === '手机下载' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[3])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.name === '官方推荐' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[8])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.name === '另类色情' && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[9])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {(i < 12 && x.name !== '官方推荐' && x.name !== '另类色情' && x.name !== '手机下载'
                                            && x.name !== '在线电影' && x.name !== '情色小说' && x.name !== '有声小说'
                                            && x.name !== '撸撸图区' && x.name !== '激情图区'
                                        ) && <>
                                                <Img
                                                    cls=""
                                                    src={appendHost(categpryImg[i])}
                                                    width={23}
                                                    height={23}

                                                />                                        </>}
                                        {i >= 12 && <>
                                            <Img
                                                cls=""
                                                src={appendHost(categpryImg[1])}
                                                width={23}
                                                height={23}

                                            />                                        </>}
                                        {x.topic ? x.topic.name : x.name}
                                    </div>

                                    <div style={{ display: 'flex', width: '50%', color: '#868686', fontSize: '12px', fontWeight: '400', placeContent: 'flex-end', alignItems: 'center', gap: '2px' }}>
                                        {x.topic ? x.topic.tags : x.tags}
                                        <Img
                                            cls="lazy vid1"
                                            style={{
                                                objectFit: 'contain',
                                                display: encryptUrls[i]?.includes("null") ? 'none' : 'block', // Hide if the URL contains "null"
                                            }}
                                            src={appendHost(encryptUrls[i])}
                                            width={20}
                                            height={20}

                                        />
                                    </div>

                                </div>

                                {indexShowSubmenu === i && <>
                                    <div className="submenuwrapper">
                                        {Array.isArray(x.data) && x.data.map((x: any, v: number) => {
                                            return <div key={v} className="submenu fl1 point">
                                                <div onClick={() => handleAuthVIP({ ...x, index: v })} >{x.topic ? x.topic.name : x.name}</div>
                                            </div>
                                        })}
                                    </div>
                                </>}
                            </>
                        })}
                        <div className="mainmenu2" onClick={() => menuHandler("novel")}>
                            <div className="mainmenucontent" style={{ display: 'flex' }} >

                                <Img
                                    cls=""
                                    src={appendHost("/images/menu/new_home_icon_xiaoshuo.png")}
                                    width={23}
                                    height={23}

                                />
                                成人小说
                            </div>

                            <div style={{ display: 'flex', width: '50%', placeContent: 'flex-end', color: '#868686', fontSize: '12px', fontWeight: '400', gap: '10px', alignItems: 'center' }}>

                                激情爽文

                                {/* <Img width={20} height={20} src={"/images/menu/home_icon_jinrifaxian.png"} 
                                        /> */}
                            </div>
                        </div>
                        <div className="mainmenu2" onClick={() => menuHandler("pic")}>
                            <div className="mainmenucontent" style={{ display: 'flex' }} >
                                <Img
                                    cls=""
                                    src={appendHost("/images/menu/new_home_icon_tuku.png")}
                                    width={23}
                                    height={23}

                                />
                                写真图库
                            </div>

                            <div style={{ display: 'flex', width: '50%', placeContent: 'flex-end', color: '#868686', fontSize: '12px', fontWeight: '400', gap: '10px', alignItems: 'center' }}>

                                每日更新色图

                                {/* <Img width={20} height={20} src={"/images/menu/home_icon_jinrifaxian.png"} 
                                        /> */}
                            </div>
                        </div>
                        <div className="mainmenu2" onClick={() => menuHandler("download")}>
                            <div className="mainmenucontent" style={{ display: 'flex' }} >
                                <Img
                                    cls=""
                                    src={appendHost("/images/menu/new_home_icon_cili.png")}
                                    width={23}
                                    height={23}

                                />
                                磁力资源
                            </div>

                        </div>
                        <div className="mainmenu2" onClick={() => menuHandler("luntan")}>
                            <div className="mainmenucontent" style={{ display: 'flex' }} >
                                <Img
                                    cls=""
                                    src={appendHost("/images/menu/new_home_icon_luntan2.png")}
                                    width={23}
                                    height={23}

                                />
                                猫咪论坛
                            </div>

                            <div style={{ display: 'flex', width: '50%', placeContent: 'flex-end', color: '#868686', fontSize: '12px', fontWeight: '400', gap: '10px', alignItems: 'center' }}>

                                更多精彩交流社区

                                {/* <Img width={20} height={20} src={"/images/menu/home_icon_jinrifaxian.png"} 
                                        /> */}
                            </div>
                        </div>
                        <div className="mainmenu2" onClick={() => handleKf()}>
                            <div className="mainmenucontent" style={{ display: 'flex' }} >
                                <Img
                                    cls=""
                                    src={appendHost("/images/menu/new_home_icon_kefu.png")}
                                    width={23}
                                    height={23}

                                />
                                在线客服
                            </div>

                            <div style={{ display: 'flex', width: '50%', placeContent: 'flex-end', color: '#868686', fontSize: '12px', fontWeight: '400', gap: '10px', alignItems: 'center' }}>

                                24小时online

                                {/* <Img width={20} height={20} src={"/images/menu/home_icon_jinrifaxian.png"} 
                                        /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}
export default Header;

const topSectionList = (data: any) => [
    {
        channel: "shipin",
        name: "中文字幕",
        url: "/page/shipin/11",
        count: 4,
    },
    {
        channel: "shipin",
        name: "国产精品",
        url: "/page/shipin/10",
        count: 3
    },
    {
        channel: "cili",
        name: "岛国无码",
        url: "/page/cili/143",
        count: 3
    },
    {
        code: "ts_",
        name: "特色专区",
        child: data?.["tesezhuanqu"]?.[0]?.child || [],
        symbol: "ts_"
    },
    {
        code: "yq_",
        name: "友情推荐",
        child: data["maomiqixia"],
        symbol: "yq_"
    },
]