import useModal, { EModalTypes } from "@/hooks/useModal"
import { FC, useEffect } from "react"
import msgstyles from "@/components/styles/msgModal.module.css"
import styles from "@/components/styles/modal.module.css"

let timeout: any;
const MsgModal: FC = () => {
    const [modalData, closeModal] = useModal()

    useEffect(() => {
        if (modalData?.data?.child) {
            return clearTimeout(timeout)
        };
        timeout = setTimeout(() => {
            closeModal();
            clearTimeout(timeout)
        }, 4000);
    }, [modalData])

    return (
        modalData?.show && modalData?.id === EModalTypes.MASK && <div className={msgstyles.msgM} onClick={closeModal}>
            <div className={`${styles.body} cls_common ${!!modalData?.data?.child && 'mask_normal'}`}>
                {
                    modalData?.data?.child ? <div>
                        {modalData?.data?.child}
                    </div> : <div className={styles.content}>
                        <div className={`${styles.content_title}`}>您已成功复制链接，快分享给好友吧～</div>
                    </div>
                }
            </div>
        </div>
    )
}
export default MsgModal