function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
var HotKey = function () {
    function HotKey(player) {
        _classCallCheck(this, HotKey);
        this.player = player;
        this.doHotKeyHandler = this.doHotKey.bind(this);
        this.cancelFullScreenHandler = this.cancelFullScreen.bind(this);
        if (this.player.options.hotkey) {
            document.addEventListener('keydown', this.doHotKeyHandler);
        }
        document.addEventListener('keydown', this.cancelFullScreenHandler);
    }
    _createClass(HotKey, [
        {
            key: 'doHotKey',
            value: function doHotKey(e) {
                if (this.player.focus) {
                    var tag = document.activeElement.tagName.toUpperCase();
                    var editable = document.activeElement.getAttribute('contenteditable');
                    if (tag !== 'INPUT' && tag !== 'TEXTAREA' && editable !== '' && editable !== 'true') {
                        var event = e || window.event;
                        var percentage;
                        switch (event.keyCode) {
                        case 32:
                            event.preventDefault();
                            this.player.toggle();
                            break;
                        case 37:
                            event.preventDefault();
                            if (this.player.options.live) {
                                break;
                            }
                            this.player.seek(this.player.video.currentTime - 5);
                            this.player.controller.setAutoHide();
                            break;
                        case 39:
                            event.preventDefault();
                            if (this.player.options.live) {
                                break;
                            }
                            this.player.seek(this.player.video.currentTime + 5);
                            this.player.controller.setAutoHide();
                            break;
                        case 38:
                            event.preventDefault();
                            percentage = this.player.volume() + 0.1;
                            this.player.volume(percentage);
                            break;
                        case 40:
                            event.preventDefault();
                            percentage = this.player.volume() - 0.1;
                            this.player.volume(percentage);
                            break;
                        }
                    }
                }
            }
        },
        {
            key: 'cancelFullScreen',
            value: function cancelFullScreen(e) {
                var event = e || window.event;
                switch (event.keyCode) {
                case 27:
                    if (this.player.fullScreen.isFullScreen('web')) {
                        this.player.fullScreen.cancel('web');
                    }
                    break;
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                if (this.player.options.hotkey) {
                    document.removeEventListener('keydown', this.doHotKeyHandler);
                }
                document.removeEventListener('keydown', this.cancelFullScreenHandler);
            }
        }
    ]);
    return HotKey;
}();
export default HotKey;