import { FC, useMemo, useState } from "react"
import useAxios from "@/hooks/useAxios";
import { useCallback, useEffect } from "react";
import ImageList from "@/components/list";
import styles from "@/components/styles/layoutContent.module.css"
import u, { ELocalKey } from "@/util";
import useCategory from "@/hooks/useCategory";
import useConfBase from "@/hooks/useConfBase";
import useUser from "@/hooks/useUser";
import Category from "@/components/category";
import Img from "@/components/img";
import { appendHost } from "@/const/append";

interface ILayoutContent {
    list: any[]
}

const LayoutContent: FC<ILayoutContent> = ({ list }) => {
    const { req } = useAxios("topic/list", 'post',);
    const { req: req2 } = useAxios("user/getTopics", "post");
    const [data, setData] = useState<any>({})
    const [config] = useConfBase()
    const category = useCategory()
    const [user] = useUser()
    const [buyList, setBuyList] = useState<any[]>([])
    const [isLoad, setIsLoad] = useState<boolean>(false)

    const fetchPurchasedList = useCallback(async () => {
        let token = '';
        if(u.isLogin() && !user?.token){
            let __data = localStorage.getItem(ELocalKey._USER);
            let __rdata = JSON.parse(u.decrypt(__data))
            token = __rdata?.token;
        }else{
            token = user?.token;
        }

        if (u.isLogin() && token) {
            const res = await req2({
                token: token
            });
            if (res) {
                setIsLoad(true)
                setBuyList(res?.data.topic_videos)
            }else{
                setIsLoad(true)
            }
        }

        if(!u.isLogin()){
            setIsLoad(true)
        }
    }, [])

    useEffect(() => {    
        fetchPurchasedList();
    }, [fetchPurchasedList])
    
    const fetchVIPVideo = useCallback(async () => {
        const res = await req({ channel: "vip" })
        if (res?.data) {
            setData(res?.data)
        }
    }, [])

    useEffect(() => {
        fetchVIPVideo()
    }, [fetchVIPVideo])

    const handleMore = useCallback((row: any) => {
        if (row) {
            return window.open(`/page/topic/${row?.id}`)
        }
    }, [category,])

    const _memoList = useMemo(() => {
        const _newdata = { ...data };
        console.log('dddd list ', _newdata);
        return _newdata['list']
    }, [data])


    return (
        <div>
             {/* <div className="mw1100 category_wrap">
             <Category showSiteCili={false} showTopicOnly={true}/>
            </div> */}
            <div className="mw1100">
                {isLoad && _memoList && _memoList.map((x: any, i: number) => {
                    return <div key={i}>
                        <div className={styles.main}>
                            <div className={styles.title}>{x.title}</div>
                            <div onClick={() => handleMore({ ...x, _name: mapName[i] })}>更多</div>
                        </div>
                        <ImageList parentId={x.id} updateTime={x?.update_time} isBuy={u.isBuyTopic(buyList, x.id)} is300={false} channel={'topic'} imgUrlkey="thumb" idkey="title" idValue="id" list={x.list} listNumShow={10}/>
                    </div>
                })}

                {!isLoad && <>
                <div style={{marginBlock:'100px', display:'flex', justifyContent:'center'}}>
                <Img cls={``}  src={appendHost(`/images/default/loading/maomiload2.gif`)} width={200} height={200} />
                </div>
                </>}

            </div>
        </div>
    )
}
export default LayoutContent;

const name = ["VIP视频", "推荐视频", "免费福利", "中文字幕", "亚洲无码", "美女主播"]
const mapName = ["VIP 专区", "短视频区", "精彩动漫", "中文字幕", "亚洲无码", "美女主播"]