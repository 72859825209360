import { Subject } from "rxjs";

export const defaultListConst$ = new Subject();

const v = {
    "maomiqixia": [
        {
            "img": "https://bjm.1vkx.cn/public/1/img/insico3.gif",
            "url": "https://instv13.com",
            "name": "Ins社区",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/91tv2.png",
            "url": "https://instv13.com",
            "name": "91大神网",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/1_0002_juzi.png",
            "url": "https://instv13.com",
            "name": "橘子直播",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/1663853729.PNG",
            "url": "https://instv13.com",
            "name": "麻豆TV",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/1663854019.png",
            "url": "https://instv13.com",
            "name": "sexMcc",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/1663853894.png",
            "url": "https://instv13.com",
            "name": "海角社区",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/IMG_3177.PNG",
            "url": "https://instv13.com",
            "name": "猫咪头条",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/1663854642.png",
            "url": "https://instv13.com",
            "name": "P站",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/insa.jpg",
            "url": "https://instv13.com/",
            "name": "女优大全",
            "status": 1
        },
        {
            "img": "https://bjm.1vkx.cn/public/1/img/1664861766.png",
            "url": "https://www.instv13.com",
            "name": "世界杯",
            "status": 1
        },
        {
            "img": "https://mj.pki.net.cn/img/9uu-50X50.jpg",
            "url": "https://www.instv13.com",
            "name": "9uu社区",
            "status": 1
        },
        {
            "img": "https://mj.pki.net.cn/img/xte-50X50.gif",
            "url": "https://www.instv13.com",
            "name": "新AV宝库",
            "status": 1
        },
        {
            "img": "https://mj.pki.net.cn/img/inswm-50_50.png",
            "url": "https://www.instv13.com",
            "name": "无码漏出",
            "status": 1
        },
        {
            "img": "https://mj.pki.net.cn/img/insdm-50_50.png",
            "url": "https://www.instv13.com",
            "name": "动漫二次元",
            "status": 1
        },
        {
            "img": "https://mj.pki.net.cn/img/allpian-50X50.jpg",
            "url": "https://www.instv13.com",
            "name": "全站免费",
            "status": 1
        }
    ],
    "tesezhuanqu": [
        {
            "name": "特色专区",
            "url": "/",
            "img": "https://js.letfab.cn/public/1/img/menu/home_icon_tesezhuanqu.png",
            "child": [
                {
                    "name": "猫咪头条",
                    "url": "https://instv13.com",
                    "img": null
                },
                {
                    "name": "91大神网",
                    "url": "https://instv13.com",
                    "img": null
                },
                {
                    "name": "女优大全",
                    "url": "https://instv13.com/",
                    "img": null
                },
                {
                    "name": "麻豆系列",
                    "url": "https://instv13.com",
                    "img": null
                },
                {
                    "name": "P站高清",
                    "url": "https://instv13.com",
                    "img": null
                },
                {
                    "name": "成人论坛",
                    "url": "https://www.instv13.com",
                    "img": null
                },
                {
                    "name": "海角社区",
                    "url": "https://instv13.com",
                    "img": null
                },
                {
                    "name": "G友原创",
                    "url": "https://www.instv13.com",
                    "img": null
                }
            ]
        }
    ]
}

defaultListConst$.subscribe((res: any) => {
    const keylen = Object.keys(res);
    if (keylen.length === 0) return;
    v["maomiqixia"] = v["maomiqixia"].map((x) => ({ ...x, url: res.yq_ts.con ? res.yq_ts.url : res.jump_ins.url }));
    v["tesezhuanqu"][0]["child"] = v["tesezhuanqu"][0]["child"].map((x) => ({ ...x, url: res.yq_ts.con ? res.yq_ts.url : res.jump_ins.url }))
})

export default v;