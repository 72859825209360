export const PROD_SITE = process.env.REACT_APP_PROD_SITE;
export const API = process.env.REACT_APP_API;
export const QUESTION_API = process.env.REACT_APP_QUESTION_API;
export const REACT_APP_STATIC_JS_ONE = process.env.REACT_APP_STATIC_JS_ONE;
export const REACT_APP_STATIC_JS_TWO = process.env.REACT_APP_STATIC_JS_TWO;
export const REACT_APP_STATIC_JS_THREE = process.env.REACT_APP_STATIC_JS_THREE;
export const REACT_APP_STATIC_JS_FOUR = process.env.REACT_APP_STATIC_JS_FOUR;
export const REACT_APP_STATIC_JS_STATIS = process.env.REACT_APP_STATIC_JS_STATIS;
export const REACT_APP_STATIC_WEB_ID_STATIS = process.env.REACT_APP_STATIC_WEB_ID_STATIS;
export const SIGN_KEY = process.env.REACT_APP_SIGN_KEY;
