
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Img from "@/components/img";
import styles from "@/components/styles/buyCardV2.module.css"
import u from "@/util";
import useConfBase from "@/hooks/useConfBase";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
import React from "react";
import { PROD_SITE } from "@/const/prod.const";
import Back from "./back";
import { appendHost, generateFullUrl } from "@/const/append";

interface IBuycard {
    smallView?: boolean
    reset?: boolean;

}

let imgDomain = "";
const regex = /(http|https):\/\//;
if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV == 'test') {
        imgDomain = `https://mj.pki.net.cn/buildtest${PROD_SITE}/`
    } else {
        imgDomain = `https://mj.pki.net.cn/build${PROD_SITE}/`
    }
    // imgDomain = `https://mj.pki.net.cn/build${PROD_SITE}/`
    // imgDomain = `https://mj.pki.net.cn/buildtest${PROD_SITE}/`
} else {
    imgDomain = ""
}


const BuyCardV2: FC<IBuycard> = ({ smallView, reset }) => {
    const [config, updateConfBase, getData] = useConfBase()
    // const { req } = useAxios("mall/goodList", "post");
    const [time, setTime] = useState<string>("24:00:00")
    const [data, setData] = useState<any[]>([])
    const { req } = useAxios("mall/goodList", "post");
    const [id, setId] = useState()

    const [selectedCard, setSelectedCard] = useState<number | null>(null);
    const [isMobile, setIsMobile] = useState(false);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [isSticky, setIsSticky] = useState(true);
    const [selectedBenefit, setSelectedBenefit] = useState([]);


    useEffect(() => {
      const handleScroll = () => {
        // Toggle sticky state based on scroll position
        if (window.scrollY >= 400 || !u.isMobile()) {
          setIsSticky(false);
        } else {
          setIsSticky(true);
        }
      };
      handleScroll();
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const navigate = useNavigate();
    const images = [
        { id: 0, src: appendHost('/images/slide-luntan.png'), alt: 'Image 1', url: u.handleJumpExternalSite("luntan", config) },
        { id: 1, src: appendHost('/images/slide-download.png'), alt: 'Image 2', url: u.handleJumpExternalSite("download", config) },
        { id: 2, src: appendHost('/images/slide-new-3.png'), alt: 'Image 3', url: '/user/charge?id=4' },
    ];

    const totalImages = images.length;

    const setNextClick = () => {
        setCurrentIndex2((prevIndex2) => (prevIndex2 === totalImages - 1 ? 0 : prevIndex2 + 1));
    };


    useEffect(() => {
        const interval = setInterval(setNextClick, 5000); // Change image every 5 seconds

        return () => clearInterval(interval); // Cleanup on unmount or when currentIndex2 changes
    }, [currentIndex2]); // Re-run effect if currentIndex2 changes



    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600); // Set true if screen width is 768px or less (mobile)
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        u.countdown(setTime)
    }, [])

    useEffect(() => {
        getData(2).then(async (res: any) => {
            if (smallView) {
                delete res[0]
            }

            if (res.length) {
                console.log('goods storage', res)
                localStorage.setItem("__GD", JSON.stringify(res));
                // res[0].description = "活动促销";
                setData(res)
                res.map((item: any) => {
                    if(item.id === 4){
                        setTimeout(() => {
                            handleSelectedCard(item.id, res)
                        }, 1000);
                    }
                    if (item.title === '终身VIP') {
                        setId(item.id)
                    }
                })
            } else {
                const res = await req()

                if (res) {
                    console.log('goods', res)
                    localStorage.setItem("__GD", JSON.stringify(res));
                    setData(res.data)
                    res.map((item: any) => {
                        if(item.id === 4){
                            setTimeout(() => {
                                handleSelectedCard(item.id, res.data)
                            }, 1000);
                        }
                        if (item.title === '终身VIP') {
                            setId(item.id)
                        }
                    })
                }
            }
        });
    }, [req])

    const handleSelectedCard = (id: number, dataList: any) => {
        setSelectedCard(id);
        console.log(dataList , '=====0tsst=====' , data , selectedCard)
        // const selectedBenefit = selectedCard ? data.find(item => item.id === selectedCard): null;
        if(dataList){
            const sel = dataList.find((item : any) => item.id === id);
            setSelectedBenefit(sel.benefits);
        }else{
            const sel = data.find((item : any) => item.id === id);
            setSelectedBenefit(sel.benefits);
        }
    }

    const handleRedirect = () => {
        if (selectedCard !== null) {
            navigate(`/user/charge?id=${selectedCard}`);
        }
    };

    const encryptUrl = (img: string) => {
        // let vidKeyParam = u.addVidKeyParam();
        let vidKeyParam = '';

        return `/${img}${vidKeyParam}`;
    };

//     const mockApiResponse = {
//         description: `<p>
//             %pic_1%
//             <p>hello world</p>
//             <p>%pic_2%<p>
//         <p>`,
//         imgs: [
//             {
//                 pic_1: "/images/slide-new-1.png",
//                 pic_2: "/images/slide-new-2.png",
//             },
//         ],
//     };

// // Process the description to replace placeholders with actual image URLs
// const processedDescription = mockApiResponse.description.replace(/%(\w+)%/g, (match, key) => {
//     const imageObj = mockApiResponse.imgs[0]; // Assuming imgs always has at least one object

//     // Check if the key matches 'pic_1' or 'pic_2'
//     if (key === 'pic_1' || key === 'pic_2') {
//         const imageUrl = imageObj[key as 'pic_1' | 'pic_2'];
        
//         // Generate the full URL for the image
//         const fullImageUrl = generateFullUrl(imageUrl, config?.mm_web_image_domain);
        
//         return `<Img src="${fullImageUrl}" alt="${key}" style="max-width: 100%; height: auto;" />`;
//     }

//     // Return the match as it is if not 'pic_1' or 'pic_2'
//     return match;
// });


    return (
        <div className={`mw1100 ${smallView && styles.smallView}`} style={{ marginBottom: !smallView ? '100px' : '0', marginTop: '0px' }}>



                <div className={`${styles.bannerTop}`}>
                        {images.map((image, index) => (
                            <div 
                            onClick={()=>{window.open(image.url)}}>
                                    <Img
                                        cls={``}
                                        src={appendHost(image.src)}
                                        alt={image.alt}
                                        width={100}
                                        height={100} // Set image height
                                        style={{width: '100%', height: '100%', borderRadius: '8px', objectFit:'contain'}}
                                    />
                                    </div>
                        ))}
                </div>
            
            {isMobile && <>
                <div style={{ overflow: 'hidden' }}>
                    {/* Display the current active image */}
                    <Img
                        src={appendHost(images[currentIndex2].src)}
                        alt={images[currentIndex2].alt}
                        click={()=>{window.open(images[currentIndex2].url)}}
                        style={{ width: '100%', height: '50%', objectFit: 'contain' }} // Adjust to cover the container
                    />
                </div>
            </>}
            <hr></hr>



            {!smallView && <>
                <div style={{ marginTop: '20px' }}>
                    <div className={`${styles.containervip}`} style={{ cursor: 'pointer' }}>
                        {data.map((x, i) => {
                            return (<>
                            <div className={`${styles.vipitemwrapper} ${styles.box}`} style={{
                                border: selectedCard === x.id ? '2px solid transparent' : 'none',
                                borderRadius:'10px',
                                background: selectedCard === x.id ? 'linear-gradient(90deg,#FD9657,#FF0000) border-box' : 'none'
                            }}>
                            <div key={i} className={`  ${smallView && styles.svvipitemwrapper}`} onClick={() => handleSelectedCard(x.id, null)}

                            >
                            <Img cls={`${styles.vipiconv2}`} src={x.title.includes('体验') ? appendHost("/images/viptrial.png") : x.title.includes('终身') ? appendHost("/images/viplifetime.png") :
                                x.title.includes('季') ? appendHost("/images/vipseason.png") : appendHost("/images/vipmonthly.png")} width={82} height={82} />
                            <div className={`${styles.gridrowsvip}`}>
                                <div className={`${styles.vipitemtitlewrapperv2}`}>
                                    <div className={`${styles.vipitemtitlev2}`}>{x.title}</div>
                                    {x.activity === 1 && <> <div className={`${styles.viplabelv2}`}>限时售卖</div></>}
                                    {x.is_hot === 1 && <> <div className={`${styles.viplabelv2}`}>限时优惠</div></>}
                                </div>
                                <div className={`${styles.vippricev2}  ${smallView && styles.svvippricev2}`}>
                                    <div style={{ color: '#FF0C07' }}>{x.cost} <span className={`${styles.vippriceyuanv2}`}>元</span></div>
                                    <div className={`${styles.vipoldpricev2}  ${smallView && styles.svvipoldpricev2}`}>
                                        <div>原价{x.old_cost}</div>
                                    </div>
                                </div>
                                {!!x.description && (
                                    <div className={`${styles.activitymark}`} style={{ color: x.title.includes('体验') ? '#4B7083' : x.title.includes('终身') ? '#523017' : x.title.includes('季') ? '#3764DB' : '#524281' }}
                                    dangerouslySetInnerHTML={{ __html: x.description.replace(/<p\b([^>]*)>/g, '<div$1>').replace(/<\/p>/g, '</div>') }}>
                                    </div>
                                )}

                            </div>


                            </div>
                            </div>

                            </>)
                        })}
                    </div>
                    <hr />
                    <div className={styles.title} style={{ color: '#FF8E9D', textAlign: 'center', paddingBlock: '10px', fontWeight: '550' }}>
                        当前会员享受以下权益
                    </div>
                    <div className={styles.content} style={{ color: '#707070', textAlign: 'center', paddingBlock: '10px' }}>
                        专属VIP的精彩视频,给您打造独特享有的专属区域,专属VIP高清视频观看线路,会员无限次观看视频,拥有会员优先观看
                    </div>
                    <div className={styles.content} style={{ color: '#707070', textAlign: 'center', paddingBlock: '10px' }}>
                        拥有专属的客服,为您一对一专属福解决各种问题,获得VIP专属专区,能观看更多精彩刺激的的视频
                    </div>
                    {selectedBenefit && selectedBenefit ? (
                        <div className={styles.containerbenefit}>
                            {selectedBenefit?.map((x: any, i: number) => (
                                <div key={i} className={styles.gridbenefit}>
                                    <div>
                                        <Img reload={reset} encryptUrl={encryptUrl(x.image)} cls="i_cvr" src={encryptUrl(x.image)} style={{ width: '0%', height: '0%' }}>
                                            {(_decryptUrl: any) => {
                                                return <>
                                                    {_decryptUrl &&
                                                        <Img reload={reset} cls="lazy list icon" encryptUrl={encryptUrl(x.image)} style={{
                                                            objectFit: 'contain',
                                                        }} src={_decryptUrl} />
                                                    }
                                                </>
                                            }}
                                        </Img>
                                    </div>
                                    <div>
                                        <div className={styles.content} style={{ color: '#FF8E9D', paddingTop: '5px', paddingBottom: '5px', fontWeight: '500' }}>
                                            {x.title}
                                        </div>
                                        <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: x.description.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>')
                                                }}
                                                className={styles.content}
                                                style={{
                                                    color: '#707070',
                                                    fontWeight: '400',
                                                    marginBlockStart: '0 !important'
                                                }}
                                            />                                    </div>
                                    </div>

                                </div>
                            )
                            )}
                        </div>
                    ) : (
                        <div>
                        </div>
                    )}
                    {selectedCard !== null && (
                        <>
                        <div style={{ textAlign: 'center' }} id="main-btn">
                            <button onClick={handleRedirect} style={{ borderRadius: '30px', marginTop: '20px', height: '50px', width: isMobile ? '100%' : '400px', background: isMobile ? '#FF8E9D' : 'linear-gradient(90deg,#FB8EB7,#EAA6C7,#D9BFD9)', color: '#fff', border: 'none', cursor: 'pointer', marginInline: '0' }}>
                                点击充值
                            </button>
                        </div>

                        {/* scrolling */}
                        {isSticky && <>
                        <div style={{ textAlign: 'center' }} id="main2-btn">
                            <button onClick={handleRedirect} style={{
                            position: isSticky ? 'fixed' : 'relative',
                            bottom: isSticky ? '35px' : 'auto',
                            textAlign: 'center',
                            borderRadius: '30px',
                            marginTop: '20px',
                            height: '50px',
                            width: isMobile ? '92%' : '400px',
                            background: isMobile ? 'rgb(255 142 157 / 94%)' : 'linear-gradient(90deg,#FB8EB7,#EAA6C7,#D9BFD9)',
                            color: '#fff',
                            border: 'none',
                            cursor: 'pointer',
                            marginInline: '0',
                            zIndex: '99999',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            点击充值
                            </button>
                        </div>
                        </>}
                     
                      </>
                    )}
                </div>
                {/* <div dangerouslySetInnerHTML={{ __html: processedDescription  }} />             */}
                </>}


        </div>
    )
}
export default BuyCardV2;
