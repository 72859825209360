function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
import utils from './utils';
var Comment = function () {
    function Comment(player) {
        var _this = this;
        _classCallCheck(this, Comment);
        this.player = player;
        this.player.template.mask.addEventListener('click', function () {
            _this.hide();
        });
        this.player.template.commentButton.addEventListener('click', function () {
            _this.show();
        });
        this.player.template.commentSettingButton.addEventListener('click', function () {
            _this.toggleSetting();
        });
        this.player.template.commentColorSettingBox.addEventListener('click', function () {
            var sele = _this.player.template.commentColorSettingBox.querySelector('input:checked+span');
            if (sele) {
                var color = _this.player.template.commentColorSettingBox.querySelector('input:checked').value;
                _this.player.template.commentSettingFill.style.fill = color;
                _this.player.template.commentInput.style.color = color;
                _this.player.template.commentSendFill.style.fill = color;
            }
        });
        this.player.template.commentInput.addEventListener('click', function () {
            _this.hideSetting();
        });
        this.player.template.commentInput.addEventListener('keydown', function (e) {
            var event = e || window.event;
            if (event.keyCode === 13) {
                _this.send();
            }
        });
        this.player.template.commentSendButton.addEventListener('click', function () {
            _this.send();
        });
    }
    _createClass(Comment, [
        {
            key: 'show',
            value: function show() {
                this.player.controller.disableAutoHide = true;
                this.player.template.controller.classList.add('dplayer-controller-comment');
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.player.container.classList.add('dplayer-show-controller');
                this.player.template.commentInput.focus();
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.template.controller.classList.remove('dplayer-controller-comment');
                this.player.template.mask.classList.remove('dplayer-mask-show');
                this.player.container.classList.remove('dplayer-show-controller');
                this.player.controller.disableAutoHide = false;
                this.hideSetting();
            }
        },
        {
            key: 'showSetting',
            value: function showSetting() {
                this.player.template.commentSettingBox.classList.add('dplayer-comment-setting-open');
            }
        },
        {
            key: 'hideSetting',
            value: function hideSetting() {
                this.player.template.commentSettingBox.classList.remove('dplayer-comment-setting-open');
            }
        },
        {
            key: 'toggleSetting',
            value: function toggleSetting() {
                if (this.player.template.commentSettingBox.classList.contains('dplayer-comment-setting-open')) {
                    this.hideSetting();
                } else {
                    this.showSetting();
                }
            }
        },
        {
            key: 'send',
            value: function send() {
                var _this2 = this;
                this.player.template.commentInput.blur();
                if (!this.player.template.commentInput.value.replace(/^\s+|\s+$/g, '')) {
                    this.player.notice(this.player.tran('please-input-danmaku'));
                    return;
                }
                this.player.danmaku.send({
                    text: this.player.template.commentInput.value,
                    color: utils.color2Number(this.player.container.querySelector('.dplayer-comment-setting-color input:checked').value),
                    type: parseInt(this.player.container.querySelector('.dplayer-comment-setting-type input:checked').value)
                }, function () {
                    _this2.player.template.commentInput.value = '';
                    _this2.hide();
                });
            }
        }
    ]);
    return Comment;
}();
export default Comment;