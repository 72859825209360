import Back from "@/components/back"
import CategoryHead from "@/components/categoryHead"
import { FC, useEffect, useState } from "react"
import Footer from "@/components/footer"
import BuyCardV2 from "@/components/buyCardV2"
import useRoleAuth from "@/hooks/useRoleAuth"
import { EStatus, PaymentSubject$ } from "@/components/modal/paymentModal"
import u, { ELocalKey } from "@/util"
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"



const UserBuy: FC = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600); // Set true if screen width is 768px or less (mobile)
    };
    
    useEffect(() => {
        // Add event listener to track window size changes
        window.addEventListener('resize', handleResize);
    
        // Initial check for screen size when the component mounts
        handleResize();
    
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useRoleAuth();
    useEffect(() => {
        if (u.isVip()) return;
        PaymentSubject$.next({
            status: EStatus.NOBUY
        })
    }, [])

    useEffect(() => {
        let isLoginfromMk = false;
        try {
            isLoginfromMk = u.decrypt(localStorage.getItem(ELocalKey._MKLOGIN))
        } catch {}

        if (isLoginfromMk) {
            localStorage.removeItem(ELocalKey._MKLOGIN)
            return modalSubject$.next({
                id: EModalTypes.MOONCAKE
            })
        }
    }, [])
    return (
        <div className="mt40">
            <CategoryHead>
            {isMobile && <>
                <div className="" style={{paddingTop:isMobile ? '0' : '40px'}}>
                    <Back cls={`pblock10`} useWhiteIcon>购买VIP</Back>
                </div>
            </>}
                <div className="con_lay mh700 mt40">
                    {/* <div className="mt40">
                        <Back>VIP购买</Back>
                    </div> */}

                    <div className="plr fl justify_center">
                        <BuyCardV2 />
                    </div>
                </div>
                <Footer />
            </CategoryHead>
        </div>
    )
}
export default UserBuy;
