
import Back from "@/components/back"
import Button from "@/components/button"
import Footer from "@/components/footer"
import CategoryHead from "@/components/categoryHead"
import { vID, validFn, validMsg } from "@/const/validate.const"
import useUpdateForm from "@/hooks/useUpdateForm"
import useValidation from "@/hooks/useValidation"
import React, { FC, useCallback, useEffect, useState } from "react"
import styles from "./styles/bindphone.module.css"
import Select from "@/components/select"
import { areaCodes } from "@/const/areaCode"
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"
import useAxios from "@/hooks/useAxios"
// import Modal from "@/components/modal"
import useUser from "@/hooks/useUser"
import useRoleAuth from "@/hooks/useRoleAuth"
import u from "@/util"

let int = '' as any;
const BindPhone: FC = () => {
    useRoleAuth()
    const [formData, setFormData] = useState<any>({
        code: "",
        phone: "",
    })
    const [selectVal, setSelectValue] = useState<string>("+86")
    
    const [user] = useUser()
    const [isClick, setIsClick] = useState<boolean>(false);
    const [timer, setTimer] = useState<any>(60)
    const { req: req2 } = useAxios("phone/sendSms", 'post');
    const { req: req3 } = useAxios("user/bind", 'post');
    const updateForm = useUpdateForm(setFormData);
    const [isValidSubmit, errMsg] = useValidation(formData, {
        phone: [vID.TRUE_FORMAT_PHONE, vID.PHONE_NUMBER],
        code: [vID.CODE]
    });

    const changeLoder = useCallback((x: any) => {
        return {
            name: `${x.value}${x.name}`,
            value: x.value
        }
    }, [])


    const updateTime = useCallback(() => {
        setIsClick(true)
        int = setInterval(function () {
            setTimer((t: any) => t - 1);
        }, 1000);
    }, [])

    useEffect(() => {
        let _t = timer;
        if (_t <= 0) {
            setTimer(60);
            setIsClick(false)
            return clearInterval(int)
        }
    }, [timer])


    const fetchCode = useCallback(async () => {
        if (formData.isClick) return;
        if (!formData.phone) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: "请填写正确的手机号码",
                btn: true,
            })
        }
        const id = vID.PHONE_AREA_CODE;
        if (!validFn[id](selectVal)) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: validMsg(id),
                btn: true,
            })
        }

        const _data = { ...formData };
        const res = await req2({ ..._data, token: user?.token, phone_code: selectVal, type: "bind" });

        if (res?.code === 0) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: "发送成功，请注意查收",
                btn: true,
            })
        } else {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res.msg,
                btn: true,
                event: () => {
                    updateTime()
                }
            })
        }
    }, [updateTime, formData, selectVal])

    const bindPhone = useCallback(async () => {
        const id = vID.PHONE_AREA_CODE;
        if (!validFn[id](selectVal)) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: validMsg(id),
                btn: true,
            })
        }
        

        const _data = { ...formData, phone_code: selectVal };
        const _msg = errMsg.filter((x: any) => typeof x !== "boolean") 
        if (_msg.length > 0) {
            modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: _msg[0],
                btn: true,
            })
        }
        if (!isValidSubmit) return;
        
        const res = await req3({..._data, token: user?.token});
        if (res?.code === 0) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res?.msg,
                btn: true,
                btnName: "立即跳转",
                event: () => {
                    return u.open('/user/setting')
                }
            })
        } else {
            modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res?.msg,
                btn: true,
            })
        }

    }, [isValidSubmit, errMsg, user, formData, selectVal])

    return (
        <>
            <CategoryHead>
                <div className="plr mh700 mt80">
                    <Back>绑定手机号码</Back>
                    <div className="mw1100">
                        <div className={styles.con}>
                            <div>绑定手机号码</div>
                            <div>
                                <div>验证你需绑定的手机号码</div>
                                <div className={styles.cont}>
                                    <div className={`grid ${styles.first} gap10`}>
                                    <Select value={selectVal} loader={changeLoder} list={areaCodes} onChange={setSelectValue}></Select>
                                        <div className={styles.bggrey}>
                                            <input onChange={(e: any) => updateForm({  ...formData, "phone": e.target.value })} type="text" placeholder="输入手机号码" />
                                        </div>
                                    </div>
                                    <div className={styles.second}>
                                        <div className={`${styles.bggrey} ${styles.bggrey1}`}>
                                            <input onChange={(e: any) => updateForm({  ...formData, "code": e.target.value })} type="text" placeholder="输入验证码" />
                                        </div>
                                        {isClick ? timer : <Button event={fetchCode} cls="btn_sty2">获取验证码</Button>}
                                    </div>
                                </div>
                                <div className={styles.b_btn}>
                                    <Button event={bindPhone} cls="btn_sty2">绑定号码</Button>
                                </div>
                            </div>
                        </div>
                        <div className="text_center mt20">无法接收验证码，<span className="kf" onClick={() => window.open(user?.custom_url)}>请联系客服</span></div>
                    </div>
                </div>
                <Footer />
            </CategoryHead>
            {/* <Modal /> */}
        </>
    )
}
export default BindPhone;