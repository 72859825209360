import { useCallback, useEffect } from "react";

const useFocus = (fn: any) => {

    const wrapperFn = useCallback((event: any) => {
        if (event.keyCode === 13 && typeof fn === "function") {
            fn()
        }
    }, [fn])

    useEffect(() => {
        document.body.addEventListener('keyup', wrapperFn);
        return () => {
            document.body.removeEventListener('keyup', wrapperFn);
        }
    }, [wrapperFn]);
}
export default useFocus