function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
import utils from './utils';
var FullScreen = function () {
    function FullScreen(player) {
        var _this = this;
        _classCallCheck(this, FullScreen);
        this.player = player;
        this.lastScrollPosition = {
            left: 0,
            top: 0
        };
        this.player.events.on('webfullscreen', function () {
            _this.player.resize();
        });
        this.player.events.on('webfullscreen_cancel', function () {
            _this.player.resize();
            utils.setScrollPosition(_this.lastScrollPosition);
        });
        this.fullscreenchange = function () {
            _this.player.resize();
            if (_this.isFullScreen('browser')) {
                _this.player.events.trigger('fullscreen');
            } else {
                utils.setScrollPosition(_this.lastScrollPosition);
                _this.player.events.trigger('fullscreen_cancel');
            }
        };
        this.docfullscreenchange = function () {
            var fullEle = document.fullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (fullEle && fullEle !== _this.player.container) {
                return;
            }
            _this.player.resize();
            if (fullEle) {
                _this.player.events.trigger('fullscreen');
            } else {
                utils.setScrollPosition(_this.lastScrollPosition);
                _this.player.events.trigger('fullscreen_cancel');
            }
        };
        if (/Firefox/.test(navigator.userAgent)) {
            document.addEventListener('mozfullscreenchange', this.docfullscreenchange);
            document.addEventListener('fullscreenchange', this.docfullscreenchange);
        } else {
            this.player.container.addEventListener('fullscreenchange', this.fullscreenchange);
            this.player.container.addEventListener('webkitfullscreenchange', this.fullscreenchange);
            document.addEventListener('msfullscreenchange', this.docfullscreenchange);
            document.addEventListener('MSFullscreenChange', this.docfullscreenchange);
        }
    }
    _createClass(FullScreen, [
        {
            key: 'isFullScreen',
            value: function isFullScreen() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                switch (type) {
                case 'browser':
                    return document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
                case 'web':
                    return this.player.container.classList.contains('dplayer-fulled');
                }
            }
        },
        {
            key: 'request',
            value: function request() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                var anotherType = type === 'browser' ? 'web' : 'browser';
                var anotherTypeOn = this.isFullScreen(anotherType);
                if (!anotherTypeOn) {
                    this.lastScrollPosition = utils.getScrollPosition();
                }
                switch (type) {
                case 'browser':
                    if (this.player.container.requestFullscreen) {
                        this.player.container.requestFullscreen();
                    } else if (this.player.container.mozRequestFullScreen) {
                        this.player.container.mozRequestFullScreen();
                    } else if (this.player.container.webkitRequestFullscreen) {
                        this.player.container.webkitRequestFullscreen();
                    } else if (this.player.video.webkitEnterFullscreen) {
                        this.player.video.webkitEnterFullscreen();
                    } else if (this.player.video.webkitEnterFullScreen) {
                        this.player.video.webkitEnterFullScreen();
                    } else if (this.player.container.msRequestFullscreen) {
                        this.player.container.msRequestFullscreen();
                    }
                    break;
                case 'web':
                    this.player.container.classList.add('dplayer-fulled');
                    document.body.classList.add('dplayer-web-fullscreen-fix');
                    this.player.events.trigger('webfullscreen');
                    break;
                }
                if (anotherTypeOn) {
                    this.cancel(anotherType);
                }
            }
        },
        {
            key: 'cancel',
            value: function cancel() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                switch (type) {
                case 'browser':
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.webkitCancelFullscreen) {
                        document.webkitCancelFullscreen();
                    } else if (document.msCancelFullScreen) {
                        document.msCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    break;
                case 'web':
                    this.player.container.classList.remove('dplayer-fulled');
                    document.body.classList.remove('dplayer-web-fullscreen-fix');
                    this.player.events.trigger('webfullscreen_cancel');
                    break;
                }
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                if (this.isFullScreen(type)) {
                    this.cancel(type);
                } else {
                    this.request(type);
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                if (/Firefox/.test(navigator.userAgent)) {
                    document.removeEventListener('mozfullscreenchange', this.docfullscreenchange);
                    document.removeEventListener('fullscreenchange', this.docfullscreenchange);
                } else {
                    this.player.container.removeEventListener('fullscreenchange', this.fullscreenchange);
                    this.player.container.removeEventListener('webkitfullscreenchange', this.fullscreenchange);
                    document.removeEventListener('msfullscreenchange', this.docfullscreenchange);
                    document.removeEventListener('MSFullscreenChange', this.docfullscreenchange);
                }
            }
        }
    ]);
    return FullScreen;
}();
export default FullScreen;