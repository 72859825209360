import { FC, useMemo } from "react";

export type Tlist = { name: string, id: number }

interface ILoginSwtichBar {
    list: Tlist[];
    event: (selectedId: number) => void;
    value: any;
    selectedCls?: string;
    unSelectedCls?: string;
}

const LoginSwitchBar: FC<ILoginSwtichBar> = ({ list, event, value, selectedCls, unSelectedCls }) => {
    const switchCss = useMemo(() => ({ "gridTemplateColumns": `repeat(${list.length}, 1fr)` }), [list]) as any;
    const mapSelect = (id: number) => {
        const _css = id === value ? selectedCls : unSelectedCls
        return `${_css} point`
    }

    return (
        <div className="grid" style={switchCss}>
            {list.map((x, i) => {
                return <div key={i} className={mapSelect(x.id)} onClick={() => event(x.id)}>{x.name}</div>
            })
            }
        </div>
    )
}
export default LoginSwitchBar;