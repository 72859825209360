import ImageList from "@/components/topicList";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import { useParams } from "react-router";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import u, { ELocalKey, cancelAllPreviousFetchRequests } from "@/util";
// import Back from "@/components/back";
import styles from "./styles/topic.module.css"
import Img from "@/components/img";
import Button from "@/components/button";
import useUser from "@/hooks/useUser";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import copy from "copy-to-clipboard";
import Back from "@/components/back";
import BuyTopicModal from "@/components/modal/buyTopicModal";
import useFlipFlop from "@/hooks/useFlipFlop";
import DownloadZip from "@/components/modal/downloadZip";
import Category from "@/components/category";
import useConfBase from "@/hooks/useConfBase";
import useCategory from "@/hooks/useCategory";
import { appendHost, generateFullUrl } from "@/const/append";


const CategoryTopic: FC = () => {
    const backImg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAjhJREFUWEftljuIE0EYx78vDwyIjyaFdtpoFdjZiSCpbHwUZxe9vVhrcYdYWiiKYGElhy9QfJ5oIbaCIGITkDAzpkmjkAVrReEs1iL5y8ispJBkd7Nkm9tuh5n9/fb/zXwMU8EPF8ynLYHcE2g2m00A6wB+12q1k91ud3NamXMVEEIcZ+ZHRLTHQpn5iFLqw0IEhBAdZn5IRNsc/JJS6vqsTZ5LAr7vnyei9RgGYM0Yc2cW/K9okknT5kgprwK4MjGno7V+kfS7cwkIIW4x85qD/WTmjlLqTVJ45gTa7XZ5OBxuEFHgYCGAFWPMxzTwTAKe59VLpdJzIjrqYJ/K5fJyr9f7nBaeWsD3/YPMvAFAOth7Ijqttf6WBZ5KQErZAmBj32cXAnhtjDlFROOs8MQCQoglZrax73SwB1rrs/OA47UzT0Gr1doRRdFXItrtFt3QWl/MA54ogUajsb1arYZEVC9EwEJdCWz9d7k2e18pdS6PFGaWIIZ4nnfYHb/9buyV1np5IZswlpBSHgDwjIgOuZPwrlKp2B7wPWsaiROYSKLOzE+Z+YQb06PRKOj3+1+ySKQWsBDbisMwfALgjIMOmTlQSvXSSmQSmCjJTQAX3PsPAIEx5m0aibkELMj3/ctEdO1fY2FeUUq9TCoxt4A7pqvMfHsCuqq1vptEIhcBC5JSBgAeF3Ili/9UCHHMXUr3uoa1uEtpLGGv5ePx+B4RbUZRtDQYDH5NK0VuJUhS7//N2RIoPIE/bp7AIawLvYQAAAAASUVORK5CYII=`

    const { channel, id } = useParams()
    const { req } = useAxios("topic/details", "post");
    const { req: req2 } = useAxios("user/getTopics", "post");
    const [reset, setReset] = useState<boolean>(false)
    const [list, setList] = useState<any[]>([])
    const [isBuy, setIsBuy] = useState<boolean>(false)
    const [dta, setDta] = useState<any>({})
    const [user] = useUser()
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [encryptUrls, setEncryptUrls] = useState<string[]>([])
    const [config] = useConfBase();
    const [showGif, setShowGif] = useState<any>("")
    const [freeList, setFreeList] = useState<any[]>([]);
    const [paidList, setPaidList] = useState<any[]>([]);
    const [isMobile, setIsMobile] = useState(false);
    const [activeTab, setActiveTab] = useState<number>(0); // 0: Highlights, 1: Free Preview, 2: Video List
    const [formattedDate, setFormattedDate] = useState<string>('');
    const category = useCategory()




    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600); // Set true if screen width is 768px or less (mobile)
    };

    useEffect(() => {
        // Add event listener to track window size changes
        window.addEventListener('resize', handleResize);

        // Initial check for screen size when the component mounts
        handleResize();

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log('ismobile?', isMobile)
    const fetchPurchasedList = useCallback(async () => {
        let token = '';
        if (u.isLogin() && !user?.token) {
            let __data = localStorage.getItem(ELocalKey._USER);
            let __rdata = JSON.parse(u.decrypt(__data))
            token = __rdata?.token;
        } else {
            token = user?.token;
        }

        if (u.isLogin() && token) {
            const res = await req2({
                token: token
            });
            if (res) {
                setIsLoad(true)
                const isBuy = u.isBuyTopic(res?.data.topic_videos, id);
                
                setIsBuy(isBuy)
                fetchDataList(isBuy ? 1 : 0)
            } else {
                setIsLoad(true)
                fetchDataList(isBuy ? 1 : 0)
            }
        }else{
            fetchDataList(0)
        }

    }, [])

    const redirectTop = useCallback((type: string, dataInfo: any, categoryList: any) => {
        console.log('redirectTop dataInfo', dataInfo)
        console.log('redirectTop categoryList', categoryList)
        const search = u.searchIdsByChannelName(categoryList, dataInfo?.list?.channel, dataInfo?.list?.jump_name); // TODO
        // const search = u.searchIdsByChannelName(categoryList, "vip", "mmtj"); // TODO

        console.log('redirectTop search', search)

        if(type === 'topic'){
            return window.location.href = '/topic'
        }
        if(type === 'home'){
            return window.location.href = '/home'
        }
        if(type === 'main'){
            return;
            // return window.location.href = '/page/'+search.channelInfo?.channel + '/' + search.channelInfo?.id
        }
        if(type === 'sub'){
            if(window.location.href.indexOf("tese") >=0 ){
                return window.location.href = '/page/tese/'+ dataInfo?.host?.id;
            }
            let path = (search.subInfo) ? (search.channelInfo?.channel + '/' + search.subInfo?.id) : (search.channelInfo?.channel + '/' + dataInfo?.info?.topic_id);
            return window.location.href = '/page/'+path
        }
    }, [])

    useEffect(() => {
        fetchPurchasedList();
    }, [fetchPurchasedList])


    const fetchDataList = useCallback(async (isBought?: number) => {
        if (!channel) return;
        const params = {
            channel,
            id,
            page: 1,
            status: isBought
        }
        const res = await req(params);
        if (res) {
            console.log('details', res)
            const imgurl = res?.data?.list?.gif_images?.map((x: any) => `${x}`);
            console.log('gif =============== ', imgurl);
            setEncryptUrls(imgurl)

            setDta(res?.data?.list);
            // if (Array.isArray(res?.data?.list?.list) && Array.isArray(res?.data?.list?.free_list)) {
            //     let mergeList: any[] = [];
            //     res?.data?.list?.free_list?.map((item: any) => {
            //         item.is_free = true;
            //         mergeList.push(item);
            //     })
            //     res?.data?.list?.list?.map((item: any) => {
            //         item.is_free = false;
            //         mergeList.push(item);
            //     })
            //     console.log('mergeList ---- ', mergeList)
            //     setList(mergeList)
            // }

            const newFreeList = res?.data?.list?.free_list.map((item: any) => ({ ...item, is_free: true }));
            const newPaidList = res?.data?.list?.list.map((item: any) => ({ ...item, is_free: false }));
            const totalPaidItems = paidList ? paidList.length : 0;

            setFreeList(newFreeList);
            setPaidList(newPaidList);

            if (!u.isLogin()) {
                setIsLoad(true)
            }
        }
    }, [channel, id])

    const goExternal = useCallback(() => {
        // const __val = u.getStatsParams(false, "tese");
        // await req2({ ...__val, category: "external", name: dta?.host?.url })
        console.log(list)
        return window.open(dta?.host?.url)
    }, [dta])

    useEffect(() => {
        const timestamp = 1728547648; // Example timestamp in seconds
        if (!isNaN(timestamp) && timestamp > 0) {
            const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

            // Check if date conversion was successful
            if (!isNaN(date.getTime())) {
                const dateString = date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
                setFormattedDate(dateString);
                console.log("Formatted Date:", dateString); // Debugging line
            } else {
                console.error("Invalid date generated from timestamp");
            }
        } else {
            console.error("Invalid timestamp value");
        }
    }, []);


    const handleBuy = useCallback((price?: any) => {
        let id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length);
        if (!u.isLogin()) {
            localStorage.setItem(ELocalKey._BT, id + '-' + price);
            return window.location.href = "/login"
        }
        try {
            let i = price.toString();
            // i = u.encrypt(i);
            u.open(`/user/chargetopic?id=` + id + `&i=` + i + `&ty=topic`)
        } catch (err) {
            console.log(err)
        }
    }, [])

    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")
    const popupModal = useCallback((price?: any) => {
        modalSubject$.next({
            id: EModalTypes.BUYTOPIC,
            data: { price: price },
            event: () => flipHander(0)
        })
    }, [])



    const copyVal = useCallback((val: any) => {
        // if (!u.isMobile()) return;
        copy(val);
        modalSubject$.next({
            id: EModalTypes.MASK,
            child: "您已成功复制下载链接～"
        })
    }, [])

    const TopicJumpEl = useCallback((btm?: boolean) => {
        return <>
            {/* <div className={styles.ts_sin_vid}><span onClick={goExternal}>本区</span>视频由{dta?.host?.nickname}独家提供</div> */}
            {/* <div className={`${styles.ts_container} ${styles.main1} ${btm ? 'mb20' : ''}`}>
                <div onClick={goExternal}>
                    <Img src={dta?.host?.logo} cls={`${styles.ts_logo}`} />
                </div>
                <div className="grid gap10">
                    <div className="fl align_center ">
                        <div className={styles.ts_title}>
                            {dta?.host?.nickname}
                        </div>

                        <Button event={goExternal} cls={`btn_sty2 ${styles.ts_btn}`}>
                            进入官网
                        </Button>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.desc}>
                        {u.dot(dta?.host?.desc)}
                    </div>
                </div>
            </div> */}
        </>
    }, [goExternal, dta])

    const handleVIP = useCallback((row: any) => {
        if (!u.isLogin()) {
            return window.location.href = "/login"
        }

        try{
            let __data = localStorage.getItem("_USER");
            if(__data){
                let userinfo = JSON.parse(u.decrypt(__data));
                if(userinfo && userinfo.username){
                    u.sendVIPLogDomain(userinfo.username, 'from topic handle vip - ' + u.isVip() + ' - ' + u.isLogin());
                }
            }
        }catch(e){}

        if (!u.isVip()) {
            return window.location.href = "/user/buy"
        }
    }, [])

    const dlZip = useCallback((val: any) => {
        // if (!u.isMobile()) return;
        copy(val);
        modalSubject$.next({
            id: EModalTypes.DLZIP,
            data: { url: val },
            event: () => flipHander(0)
        })
    }, [])

    // const mockApiResponse = {
    //     description: `<p>
    //         %pic_1%
    //         <p>hello world</p>
    //         <p>%pic_2%<p>
    //                     <p>%pic_3%<p>

    //     <p>`,
    //     imgs: [
    //         {
    //             pic_1: "/images/slide-new-1.png",
    //             pic_2: "/images/slide-new-2.png",
    //             pic_3: "/images/slide-new-3.png",
    //         },
    //     ],
    // };
    const processedDescription = (dta?.desc || "").replace(/%(\w+)%/g, (match: any, key: any) => {
        const imageObj = dta?.desc_images ?? {}; // Assuming imgs always has at least one object
    
        // Check if the key matches 'pic_1' or 'pic_2'
        if (key in imageObj) {
            const imageUrl = imageObj[key as keyof typeof imageObj];
            
            // Generate the full URL for the image
            const fullImageUrl = appendHost(imageUrl);
            
            return `<Img src="${fullImageUrl}" alt="${key}" style="max-width: 100%; height: auto;" />`;
        }
    
        // Return the match as it is if not 'pic_1' or 'pic_2'
        return match;
    });

    

    return (
        <div className="grid gap10">
            {<DownloadZip />}
            {/* <div className="mw1100 category_wrap">
             <Category showSiteCili={false} showTopicOnly={true}/>
            </div> */}
            {/* <Back cls={'mt20'}>主题详情</Back> */}
            {/* <div className="mt20"> */}
            <div className="">



                {isLoad &&
                    <>
                            <div className={`mw1100`} >
                                {isMobile ? (<>
                                       {/* <div className={`${styles.ts_title_top}`} >
                                       <Back cls={'pblock10 mt0'} useWhiteIcon>限时专题</Back>
                                       </div> */}
                                    <div style={{paddingBottom:'15px', paddingTop:'15px'}}>
                                        
                                                             <div className={`mw1100 mt20 ${styles.header_title} fl`} style={{gap:'6px'}}>
                        <span className={`${styles.direct_link} ${styles.home_direct_link}`} style={{fontWeight:'600',color: '#e77996', display:'flex', justifyContent:'center', gap: '2px'}} onClick={()=>{redirectTop('home',dta, category)}}>
                        <Img src={appendHost("/images/header/home_selected.png")} width={20} height={20} cls={` ${styles.home_icon}`}/>首页
                        </span>

                        {window.location.href.indexOf("tese")===-1 && 
                        <>
                        <img  className={` ${styles.arrow}`} src={backImg} alt="" />
                        <span className={`${styles.direct_link}  ${styles.sub_link}`} onClick={()=>{redirectTop('topic',dta,category)}}>限时专题</span>
                        </>}
                    </div>
                                    </div></>
                                ) : (
                                    <div>
                                <div className={`${styles.ts_title_top}`}>
                                <Back cls={'mt20 pblock10 pl20'} useWhiteIcon>限时专题</Back>

                                </div>
                                    </div>
                                )

                                }


                                                                        <div style={{ position: "relative", width: "100%", }}>
  {/* Image */}
{!!dta?.cover &&  

                                  <Img reload={reset} encryptUrl={isMobile ? dta?.phone_cover : dta?.cover} cls="i_cvr" src={isMobile ? dta?.phone_cover : dta?.cover} >
                                  {(_decryptUrl: any) => {
                                      return <>
                                          {_decryptUrl &&
                                              <Img reload={reset} cls="lazy list" encryptUrl={isMobile ? dta?.phone_cover : dta?.cover} style={{ objectFit: 'contain', width: "100%", height: isMobile ? "100%" : "100%" }} src={isMobile ? dta?.phone_cover : dta?.cover} />
                                          }
                                      </>
                                  }}
                              </Img>
  }

  {/* White overlay with 50% opacity */}
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    }}
  ></div>
</div>

                                <div className={` ${styles.ts_container}`}>
                                    <div >
                                        {/* <Img src={dta?.host?.logo} cls={`${styles.ts_logo}`} /> */}
                                    </div>

                                    <div style={{ textAlign: 'left', color:'#000', fontSize:'18px', fontWeight:'500', paddingLeft:'20px', paddingTop:'20px', fontFamily:'PingFang SC' }}>
                                            {dta?.title}
                                            {/* <div className={styles.importanttext}>*用户购买主题包后，请到右上角头像“个人中心” —“已购买主题包” 永久在线观看或下载</div> */}
                                        </div>
                                        <div style={{ textAlign: 'left', color:'#575757', fontSize:'14px', fontWeight:'400', paddingLeft:'20px', paddingBlock:'10px' }}>
                                            {/* {dta?.desc} */}
                                            <div>
        <div dangerouslySetInnerHTML={{ __html: processedDescription }} />
    </div>                                            {/* <div className={styles.importanttext}>*用户购买主题包后，请到右上角头像“个人中心” —“已购买主题包” 永久在线观看或下载</div> */}
                                        </div>
                                        <div style={{color:'#000', display:'flex', alignItems:'center', paddingLeft:'20px'}}>
                                        <Img src={appendHost("/images/icon/date.png")} width={20} height={20} cls={``}/>

                                        <p style={{textAlign:'start', color:'#707070', fontSize:'14px', paddingLeft:'5px'}}>{formattedDate || '2024-10-10'} | {dta?.title}</p>
                                        </div>

                                    {/* <div className={`${styles.pricetagmobile}`} style={{ fontWeight: 500, width: '100%', gap: '20px', justifyContent: 'right' }}>
                            {!isBuy && !u.isVip() && <>
                                <div style={{ color: '#FF347D', display: 'flex', alignItems: 'center', gap: '5px', justifySelf: 'flex-end' }} >VIP价格: {dta?.vip_price} 元 <div className={`${styles.becomevip}`} onClick={handleVIP}><span style={{ paddingRight: '5px' }}>开通VIP</span><Img height={12} width={12} src={appendHost("/images/youbianjiantou.png")} /></div></div>
                                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }} >当前价格: {dta?.price} 元</div>
                            </>
                            }
                            {!isBuy && u.isVip() && <>
                                <div style={{ color: '#FF347D', display: 'flex', alignItems: 'center', gap: '5px', justifySelf: 'flex-end', fontWeight: 700 }} >VIP价格: {dta?.vip_price} 元</div>
                                <div style={{ display: 'flex', alignItems: 'center', textDecoration: 'line-through' }} >价格: {dta?.price} 元</div>
                            </>
                            }
                        </div> */}

                                </div>
                                <div className={` ${styles.ts_container}`} style={{marginTop:'15px', borderRadius: isMobile ? '' : '10px'}}>

                                    <div className={`${styles.buttontag}`}>
                                    <div style={{color:'#000', display:'flex', width:'100%', alignItems:'center'}}>
                                    <Img src={appendHost("/images/icon/totalvideo.png")} width={20} height={20} cls={``} style={{paddingLeft:'20px'}}/>
                                        <div style={{paddingLeft:'5px', color:'#707070', fontSize:'14px'}}> 
                                        视频: {paidList?.length}

                                        </div>
                                                    </div>
                                        {!isBuy &&
                                            <>
                                                <div style={{display:'flex', gap:'10px', justifyContent: isMobile ? 'center' : 'end', paddingTop: isMobile ? '20px' : ''}}>

                                                    <Button event={() => { popupModal(dta?.price) }} cls={`btn_sty2 btn_gray btn_style2`}>
                                                        下载合集
                                                    </Button>
                                                    <Button event={() => { handleBuy(u.isVip() ? dta?.vip_price : dta?.price) }} cls={`btn_sty8 btn_style2`}>
                                                        立即购买
                                                    </Button>
                                                </div>
                                            </>
                                        }


                                        {isBuy && <>
                                            <div style={{display:'flex', gap:'10px', justifyContent: isMobile ? 'center' : 'end', paddingTop: isMobile ? '20px' : ''}}>
                                            <Button event={() => dlZip(dta?.file)} cls={`btn_sty2 btn_style2`}>
                                                下载合集
                                            </Button>
                                            <Button event={()=>{return false;}} cls={`btn_sty8 btn_gray btn_style2`}>
                                               已拥有
                                           </Button>
                                           </div>
                                           </>
                                        }
                                    </div>
                                    <div className={styles.left_cover}>
                                        <div style={{ width: '100%', justifyContent: 'end' }}>
                                            {!isBuy &&
                                                <>
                                                    {!isBuy && u.isVip() && <>
                                                        <div style={{textAlign:'end', fontSize: '12px', padding:'4px', paddingRight:'23px', lineHeight:'5px' }}>
                                                        <p style={{ color: 'gray', fontWeight: '500', textDecoration: 'line-through', display: isMobile ? 'block' : 'none' }}>当前售价 {dta?.price} 元</p><div style={{ color: '#FF8E9D', display: isMobile ? '' : 'flex', alignItems:'center', justifyContent:'end' }} className={`${styles.currentprice}`}>您已开通VIP会员，当前购买价格为 {dta?.vip_price} 元 <p style={{ color: 'gray', fontWeight: '500', textDecoration: 'line-through', display: isMobile ? 'none' : 'block', paddingLeft:'10px' }}>当前售价 {dta?.price} 元</p></div>

                                                        </div>
                                                    </>}
                                                    {!isBuy && !u.isVip() && <>
                                                        <div style={{alignItems:'center', fontSize: '12px', padding:'4px', paddingRight:'23px', display:'flex', justifyContent:'end' }}>
                                                            <p style={{ color: '#FF347D', paddingRight:'10px', textDecoration: 'line-through', display: isMobile ? '' : 'flex', alignItems:'center', lineHeight:'1px', paddingTop: isMobile ? '10px':'', fontSize: isMobile ? '12px' : '14px' }} className={`${styles.currentprice}`}>会员价 {dta?.vip_price} 元 </p> <div style={{ color: 'gray', fontWeight: '500', fontSize:'14px', textDecorationLine: 'none', paddingInline:'10px' }}>当前售价 {dta?.price} 元</div>
                                                            <div style={{ textAlign: 'end', display: 'flex', alignItems: 'center' }} onClick={handleVIP}>
    <Button event={() => { handleBuy(u.isVip() ? dta?.vip_price : dta?.price) }} cls={`btn_sty7 btn_style3`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ margin: 0, fontSize:'14px' }}>开通VIP</p>
            <Img 
                width={20} 
                height={20} 
                src={appendHost("/images/header/vipbutton.png")} 
                alt="VIP Button"
                style={{ marginLeft: '5px' }} // optional margin for spacing
            />
        </div>
    </Button>

</div>
                                                        </div>
                                                    </>}
                                                </>
                                            }


                                        </div>
                                    </div>
                    <div className={`${styles.img_con}`}>
                                        <div className={`mw1100 ${styles.gif_wrapper}`} style={{ marginBottom: '20px', marginTop: '20px', paddingLeft: isMobile ? '0' : '20px', paddingRight: isMobile ? '0' : '20px',width: 'auto' }}>
                                            {Array.isArray(encryptUrls) && encryptUrls.map((x, i) => {
                                                return (
                                                    <div className={`${styles.gif_images}`} >
                                                        <Img reload={reset} height={30} encryptUrl={(encryptUrls[i])} src={encryptUrls[i]} >
                                                            {(_decryptUrl: any) => {
                                                                return <>
                                                                    <div onClick={() => { _decryptUrl.includes("loading") ? setShowGif("") : setShowGif(_decryptUrl); }} style={{ position: 'relative', float: 'inline-start' }}>
                                                                        <Img src={appendHost("/images/zoom.png")} width={20} height={20} style={{ position: 'absolute', top: '0', right: '0px',borderTopRightRadius:'10px'  }} />
                                                                        {_decryptUrl &&
                                                                            <Img reload={reset} cls="lazy br10" encryptUrl={(encryptUrls[i])} style={{ objectFit: 'contain', width: "100%", height: "100%" }} src={(encryptUrls[i])} />
                                                                        }
                                                                    </div>
                                                                </>
                                                            }}
                                                        </Img>
                                                    </div>)
                                            })}
                                        </div>
                    </div>
                    
                                    <hr />
                                    <div className={styles.tabContainer} style={{marginInline:'15px', paddingBottom:'20px'}}>
                        <button className={activeTab === 0 ? styles.activeTab : ''} onClick={() => setActiveTab(0)}>视频列表</button>
                        {/* <button className={activeTab === 1 ? styles.activeTab : ''} onClick={() => setActiveTab(1)}>视频片段</button> */}
                        <button className={activeTab === 2 ? styles.activeTab : ''} onClick={() => setActiveTab(2)}>免费视频</button>
                    </div>
                                    {/* <div className={`${styles.pricetagmobile}`} style={{ fontWeight: 500, width: '100%', gap: '20px', justifyContent: 'right' }}>
                            {!isBuy && !u.isVip() && <>
                                <div style={{ color: '#FF347D', display: 'flex', alignItems: 'center', gap: '5px', justifySelf: 'flex-end' }} >VIP价格: {dta?.vip_price} 元 <div className={`${styles.becomevip}`} onClick={handleVIP}><span style={{ paddingRight: '5px' }}>开通VIP</span><Img height={12} width={12} src={appendHost("/images/youbianjiantou.png")} /></div></div>
                                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }} >当前价格: {dta?.price} 元</div>
                            </>
                            }
                            {!isBuy && u.isVip() && <>
                                <div style={{ color: '#FF347D', display: 'flex', alignItems: 'center', gap: '5px', justifySelf: 'flex-end', fontWeight: 700 }} >VIP价格: {dta?.vip_price} 元</div>
                                <div style={{ display: 'flex', alignItems: 'center', textDecoration: 'line-through' }} >价格: {dta?.price} 元</div>
                            </>
                            }
                        </div> */}

                                </div>


                            </div>

{isMobile ? (
<div>

                                <div className={`mw1100 ${styles.ts_title}`} style={{paddingLeft:'3px'}}></div>
                                <div className={`mw1100 ${styles.ts_container_tab} ${styles.main2}`}>

 

{activeTab === 2 && (
            <div>
                <ImageList tag="试看" event={popupModal} isBuy={isBuy} updateTime={dta?.update_time} title={dta?.title} topicPrice={dta?.price} reset={reset} channel={'topic'} list={freeList} idkey="title" idValue="id" imgUrlkey="thumb" isFreeList={true}>
                </ImageList>            </div>
        )}
        {activeTab === 0 && (
            <div>
                            <ImageList event={popupModal} isBuy={isBuy} updateTime={dta?.update_time} title={dta?.title} topicPrice={dta?.price} reset={reset} channel={'topic'} list={paidList} idkey="title" idValue="id" imgUrlkey="thumb" isFreeList={false}>
                            </ImageList>            </div>
        )}
                                </div>

</div>
            ) : (
                <div>

                                {/* <div className={`mw1100 ${styles.ts_title}`}>{dta?.title}</div> */}
                                {activeTab === 1 && (
                                    <div>
                                <div className={`mw1100 ${styles.gif_wrapper}`} style={{ marginBottom: '20px', marginTop: '20px' }}>
                                    {Array.isArray(encryptUrls) && encryptUrls.map((x, i) => {
                                        return (
                                            <div className={`${styles.gif_images}`} >
                                                hello
                                                <Img reload={reset} height={30} encryptUrl={(encryptUrls[i])} src={encryptUrls[i]} >
                                                    {(_decryptUrl: any) => {
                                                        return <>
                                                            <div onClick={() => { _decryptUrl.includes("loading") ? setShowGif("") : setShowGif(_decryptUrl); }} style={{ position: 'relative', float: 'inline-start' }}>
                                                                <Img src={appendHost("/images/zoom.png")} width={20} height={20} style={{ position: 'absolute', top: '0', right: '0px', borderTopRightRadius:'10px' }} />
                                                                {_decryptUrl &&
                                                                    <Img reload={reset} cls="lazy br10" encryptUrl={(encryptUrls[i])} style={{ objectFit: 'contain', width: "100%", height: "100%" }} src={encryptUrls[i]} />
                                                                }
                                                            </div>
                                                        </>
                                                    }}
                                                </Img>
                                            </div>)
                                    })}
                                </div>
                                    </div>

                                )}
                                {isLoad &&
                    <div className={`mw1100 grid gap30 ${styles.ts}`}>

{activeTab === 2 && (

                        <div>
                            <ImageList tag="试看" event={popupModal} isBuy={isBuy} updateTime={dta?.update_time} title={dta?.title} topicPrice={dta?.price} reset={reset} channel={'topic'} list={freeList} idkey="title" idValue="id" imgUrlkey="thumb" isFreeList={true}>
                            </ImageList>
                        </div>
)}
                        {activeTab === 0 && (

                        <div>
                            <ImageList event={popupModal} isBuy={isBuy} updateTime={dta?.update_time} title={dta?.title} topicPrice={dta?.price} reset={reset} channel={'topic'} list={paidList} idkey="title" idValue="id" imgUrlkey="thumb" isFreeList={false}>
                            </ImageList>
                        </div>
                        )}
                    </div>
                }
                </div>
             )}


                    </>
                }

                {!isLoad && <>
                <div style={{marginBlock:'100px', display:'flex', justifyContent:'center'}}>
                <Img cls={``}  src={appendHost(`/images/default/loading/maomiload2.gif`)} width={200} height={200} />
                </div>
                </>}


            </div>

            {/* <div>
                <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={(e) => {
                    fetchDataList(e.current);
                    setReset(true);
                }} />
            </div> */}

            {showGif && showGif !== "" && <>
                <div className={`${styles.centered_gif_wrapper}`} onClick={() => setShowGif("")}>
                    <div className={`${styles.centered_gif}`}><img src={showGif} alt="" /></div>
                </div>
            </>}

            {TopicJumpEl()}
            {<BuyTopicModal />}
            <Footer />
        </div>
    )
}
export default CategoryTopic