import CategoryHead from "@/components/categoryHead";
import Footer from "@/components/footer";
import { FC } from "react";

const ParentalControl: FC = () => {
    return <div>
        <CategoryHead>
            <div className="head_space">
                <div className="fo_grey">
                    <div className="fo_grey_h">Parental Controls</div>
                    <p>The Maomiav platform is intended to be a secure and sex-positive space for adult viewing and adult content only. We take seriously our commitment to the safety of our users and integrity of our platform.</p>
                    <div className="callout">
                        Maomiav is fully RTA compliant as rated by the Association of Sites Advocating Child Protection (ASACP), and, as a reminder, is strictly reserved for those of legal age only.
                    </div>
                    <p>As is clearly outlined in our <a href="/information/terms">Terms of Service</a>, access to our platform is strictly limited to those who affirm that :</p>
                    <div className="callout">
                        <div>
                            <li>They are at least eighteen (18) years of age (or older in any other location in which 18 is not the minimum age of majority) from which our platform is accessed.</li>
                            <li>Are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in our <a href="/information/terms">Terms of Service</a> , and to abide by and comply with said <a href="/information/terms">Terms of Service</a></li>
                            <li>The jurisdiction from which they access our platform does not prohibit the receiving or viewing of sexually explicit content.</li>
                        </div>
                    </div>
                    <p>In order to help enforce our terms and assist in restricting access to minors, we have ensured that Maomiav is, and remains, fully <a href="https://www.rtalabel.org/" target="_blank" rel="noreferrer">RTA [Restricted to Adults]</a>  compliant, and allows every page to be blocked by simple parental control tools.</p>
                    <p>We urge parents to monitor their children’s online activity and implement parental controls as appropriate to keep their children safe in the digital age. We firmly believe that parents are best placed to police their children’s activity using the plethora of tools already available in modern operating systems and devices.</p>
                    <p>We have set out below some simple guidelines and advice on how to limit the potential for your child to access inappropriate content .</p>
                    <div className="callout">
                        <h4><em> Making the Internet Child-Friendly </em></h4>
                        <p>There are many tools available for parents to make the internet safer for their children. ISPs, device manufacturers, and operating system developers have ensured an easy route to setting up parental controls. They all use simple step-by-step instructions which you only need to follow once, giving you control over your children’s browsing habits beyond simple supervision.</p>
                        <p>Maomiav’s Restricted to Adults tags ensure that all such controls can automatically block our site when enabled by parents.</p>
                    </div>
                    <div className="fo_grey_h">Parental Control Settings - Desktop Services</div>
                    <p>All modern operating systems have built-in parental controls and they are simple to activate requiring only a few minutes to setup. Microsoft Windows 10 for instance, allows parents to easily setup accounts for their children, restrict which apps and programs they can open, and block inappropriate websites at the touch of a button.</p>
                    <p>Visit the <span><a href="https://family.microsoft.com/"><em>Microsoft Family Safety</em></a></span>  site for more information.</p>
                    <p>Apple devices such as Macs, iPads, and iPhones have similar parental controls which can be enabled by following the instructions on Apple’s dedicated <a href="https://www.apple.com/families/"><em>Families site</em></a></p>

                    <div className="fo_grey_h">Parental Control Settings - Mobile Devices</div>
                    <p>As many children use their own personal devices to access the internet, mobile operating systems now include tools to ensure parents can stay firmly in control of their children’s browsing habits.</p>
                    <p>iOS devices from Apple such as iPhones and iPads can block inappropriate content, set screen time limits and prevent apps from being installed without permission. More information is available on Apple’s dedicated <a href="https://www.apple.com/families/" target="_self">Families site</a></p>
                    <p>Android products such as smartphones and tablets contain similar protections, allowing parents to choose what their children can see and do on their personal devices. The <a href="https://safety.google/families/"><em>Google Safety Centre</em></a>  will walk you through the setup process.</p>

                    <div className="fo_grey_h">Internet Service Providers</div>
                    <p>Most, if not all Internet Service Providers (ISPs) offer protections to limit the websites available to your home or handheld device. Such services block all traffic to inappropriate websites and can usually be enabled by logging into your ISP account online. Simply contact your ISP and they can advise how to enable their content blocks.</p>

                    <div className="fo_grey_h">Dedicated Parental Control Software</div>
                    <ul>
                        <li><a href="https://www.qustodio.com/">Qustodio</a></li>
                        <li><a href="https://www.kaspersky.co.uk/safe-kids">Kapersky Safe Kids</a></li>
                        <li><a href="https://www.netnanny.com/">Net Nanny</a></li>
                        <li><a href="https://family.norton.com/">Norton Family</a></li>
                        <li><a href="https://www.mobicip.com/">Mobicip</a></li>
                    </ul>

                    <div className="fo_grey_h">More Information on Digital Parenting and Supervision</div>
                    <p>If you want to find out more information on protecting your children online, how to talk to them, and how to set and agree limits, there are several resources available.</p>
                    <ul>
                        <li><a href="https://www.fosi.org/">FOSI – Family Online Safety Institute</a></li>
                        <li><a href="https://www.saferinternet.org.uk/">UK Safer Internet Centre</a></li>
                        <li><a href="https://www.internetmatters.org/">Internetmatters.org</a></li>
                    </ul>
                </div>
            </div>
        </CategoryHead>
        <Footer />
    </div>
}
export default ParentalControl;