function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
var InfoPanel = function () {
    function InfoPanel(player) {
        var _this = this;
        _classCallCheck(this, InfoPanel);
        this.container = player.template.infoPanel;
        this.template = player.template;
        this.video = player.video;
        this.player = player;
        this.template.infoPanelClose.addEventListener('click', function () {
            _this.hide();
        });
    }
    _createClass(InfoPanel, [
        {
            key: 'show',
            value: function show() {
                this.beginTime = Date.now();
                this.update();
                this.player.timer.enable('info');
                this.player.timer.enable('fps');
                this.container.classList.remove('dplayer-info-panel-hide');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.timer.disable('info');
                this.player.timer.disable('fps');
                this.container.classList.add('dplayer-info-panel-hide');
            }
        },
        {
            key: 'triggle',
            value: function triggle() {
                if (this.container.classList.contains('dplayer-info-panel-hide')) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        },
        {
            key: 'update',
            value: function update() {
                this.template.infoVersion.innerHTML = 'v'.concat(DPLAYER_VERSION, ' ').concat(GIT_HASH);
                this.template.infoType.innerHTML = this.player.type;
                this.template.infoUrl.innerHTML = this.player.options.video.url;
                this.template.infoResolution.innerHTML = ''.concat(this.player.video.videoWidth, ' x ').concat(this.player.video.videoHeight);
                this.template.infoDuration.innerHTML = this.player.video.duration;
                if (this.player.options.danmaku) {
                    this.template.infoDanmakuId.innerHTML = this.player.options.danmaku.id;
                    this.template.infoDanmakuApi.innerHTML = this.player.options.danmaku.api;
                    this.template.infoDanmakuAmount.innerHTML = this.player.danmaku.dan.length;
                }
            }
        },
        {
            key: 'fps',
            value: function fps(value) {
                this.template.infoFPS.innerHTML = ''.concat(value.toFixed(1));
            }
        }
    ]);
    return InfoPanel;
}();
export default InfoPanel;