import { FC } from "react";
import styles from "./styles/sectionBar.module.css"

interface ISectionBar {
    title: string;
    children?: React.ReactNode,
    rightText?: any;
}

const SectionBar: FC<ISectionBar> = ({ title, children, rightText }) => {
    return (
        <div>
            <div className={`${styles.title} fl`}>
                <div>{title}</div>
                <div className="mlauto">
                    {rightText && <span>当前支付金额：<span className={styles.bld}>￥{rightText || ''}</span></span>}
                </div>
            </div>
            <div className={`mt10`}>
                {children}
            </div>
        </div>
    )
}
export default SectionBar