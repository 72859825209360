const def_ins = process.env.REACT_APP_STATIC_DEF_INS;
const def_wuma_ins = process.env.REACT_APP_STATIC_DEF_WUMAINS;
// const xte = "https://xteao.xyz/";
// const vip_domain = "https://www.bc58z.com"
const version = { version: "1.0.2" };
// const download_img = 'https://mj.pki.net.cn/img/maomi_app.gif';
// const download_close = 1;
// const spe_ext = 1;
const dr_config = { jump: false, url: "/" };
const yq_ts = { con: true, url: def_ins }
const jump_ins = { jump: true, url: def_ins, wuma_url: def_wuma_ins }; 

const obj = {
    def_ins,
    def_wuma_ins,
    // vip_domain,
    dr_config,
    jump_ins,
    version,
    // download_img,
    // download_close,
    // spe_ext,
    yq_ts
} as any

const checkTypes = (value: any, originalData: any) => {
    let _val = null;
    let temp = {}
    try {
        _val = JSON.parse(value);
        if (typeof _val === "object") {
            for (let i in _val) {
                const _key = _val[i]
                const _fmt = typeof _key === "string" ? originalData[_val[i]] || '' : _key;
                temp = { ...temp, [i]: _fmt }
            }
            return temp;
        }
    } catch {
        return value;
    }
}

export const changeConfigHandler = (rawData: any) => {
    const stringObjectKeys = ["dr_config", "jump_ins", "yq_ts"];
    const len = Object.keys(rawData);
    if (len.length === 0) return;
    for ( let i in rawData) {
        if (stringObjectKeys.includes(i)) {
            obj[i] = checkTypes(rawData[i], rawData)
        } else {
            obj[i] = rawData[i]
        }
    }
    return obj;
}

export default obj