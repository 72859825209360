import { FC, useCallback, useEffect, useState } from "react"
import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Img from "./img"
import useConfBase from "@/hooks/useConfBase"
import useAxios from "@/hooks/useAxios"
import { PROD_SITE } from "@/const/prod.const"
import u, { ELocalKey } from "@/util"
import { changeConfigHandler } from "@/const/gl.const"
import { defaultListConst$ } from "@/const/defaultList.const"
// import useWindowLoadCallback from "@/hooks/useWindowEffect"

interface ISlider { }

const Slider: FC<ISlider> = () => {

    const [config, updateConfBase, getData] = useConfBase();
    const [bannerList, setBannerList] = useState<any[]>([])
    const { req: req3 } = useAxios("config/base", 'post');
    const { req: req4 } = useAxios("index/countbanner", 'post', true);
    // useWindowLoadCallback(() => {
    //     const res = u.getScriptVal(4) as any;
    //     if (Array.isArray(res)) {
    //         setBannerList(res);
    //     }
    // })

    useEffect(() => {
        u.timeoutCb(() => {
            const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__BXL));
            if (__data) {
                setBannerList(__data)
            }
        })
    }, [])


    const fetchBannerData = useCallback(async () => {
        // if (Object.keys(config).length > 0) {
        //     getData(3).then((res) => {
        //         if (res && Array.isArray(res)) {
        //             setBannerList(res);
        //         }
        //     })
        // }  
        // else {
        const res = await req3({ site: PROD_SITE })
        if (res?.data) {
            let newConfBase = changeConfigHandler(res?.data);
            defaultListConst$.next(newConfBase)
            newConfBase = { data: newConfBase };
            updateConfBase(newConfBase);
        }
        if (res?.data?.static_host && res?.data?.banner) {
            u.fetchData(`${res?.data?.static_host}${res?.data?.banner}`).then((res: any) => {
                let _res = res;
                try {
                    _res = JSON.parse(res);
                    if (_res && Array.isArray(_res)) {
                        setBannerList(_res);
                    }
                } catch { }
            })
        }
        // }
    }, [])

    // useEffect(() => {
    // fetchBannerData()
    // }, [fetchBannerData])

    const handleBannerStats = useCallback(async (x: any) => {
        const __val = u.getStatsParams(false, "", "", window.location.pathname);
        req4({ ...__val, name: x?.title, category: "banner" });
        return u.open(x?.url)
    }, [])

    return (
        <Swiper
            loop
            slidesPerView={1}
            modules={[Autoplay]}
            autoplay={{
                delay: 3000,
                disableOnInteraction: true
            }}
        >
            {bannerList.map((x: any, i) => (<SwiperSlide key={i} onClick={() => handleBannerStats(x)}>
                <Img errorCheck getFullImgUrl style={{ width: "100%", height: '100%', objectFit: 'cover' }} alt="" src={x.img} />
            </SwiperSlide>))}
        </Swiper>
    )
}
export default Slider