import 'swiper/swiper-bundle.min.css';
import { FC, useCallback, useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Img from "@/components/img"
import styles from "./styles/androiddownload.module.css"
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { PROD_SITE } from "@/const/prod.const";
import { appendHost } from "@/const/append";
import { generateQRCode } from "@/components/generateqr";


import u, { ELocalKey, ProjectInfo, teseConstant } from "@/util";

SwiperCore.use([Autoplay, Pagination]);


const AndroidDownload: React.FC = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [versionData, setVersionData] = useState<any>({});
    const [responseData, setResponseData] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalImages, setModalImages] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<any>();

    useEffect(() => {
        const loadBaiduAnalytics = () => {
            // let hmSrc: string;
            document.body.style.backgroundColor = 'black';
            document.body.style.color = 'white';

            //     hmSrc = "https://hm.baidu.com/hm.js?cf648b213ea087e67f53a1b4efc9ff0e111";

                const hmSrc = process.env.REACT_APP_STATIC_MM_APPDOWNLOAD_SRC1;

            if (hmSrc) {
                const script = document.createElement("script");
                script.src = hmSrc;
                script.async = true;
                const firstScript= document.getElementsByTagName("script")[0];
                firstScript?.parentNode?.insertBefore(script, firstScript);
            }
        };

        loadBaiduAnalytics();
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600); // Adjust for mobile screens
    };

    useEffect(() => {
        // Check screen size initially and add resize event listener
        window.addEventListener('resize', handleResize);
        handleResize();

        // Clean up the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      let isMounted = true;  // To handle component unmounting
      
      const generateQR = async () => {
        try {
          let urlToGo = '';
          if (PROD_SITE === "1") {
            urlToGo = responseData.android_app_download_1;
          } else if (PROD_SITE === "2") {
            urlToGo = responseData.android_app_download_2;
          } else {
            urlToGo = responseData.android_app_download_3;
          }
    
          const dataUrl = await generateQRCode(urlToGo);
          
          // Only update state if component is mounted
          if (isMounted) {
            setQrCodeDataUrl(dataUrl);
          }
        } catch (error) {
          console.error("Failed to generate QR code:", error);
        }
      };
    
      generateQR();
    
      // Cleanup function
      return () => {
        isMounted = false;
      };
    }, [responseData, PROD_SITE]); // Add all dependencies here

        // Fetch version.json
        useEffect(() => {
            fetch('/version.json')
                .then(response => response.json())
                .then(data => setVersionData(data))
                .catch(error => console.log(error));
        }, []);
        

        useEffect(() => {
            const downloadAPI = process.env.REACT_APP_STATIC_DOWNLOAD;
            if (!downloadAPI) {
                console.error("REACT_APP_STATIC_DOWNLOAD is not defined.");
                return;
            }

            fetch(downloadAPI)
                .then(response => response.json())
                .then(data => {
                    try {
                        console.log('show downloadapi', downloadAPI)

                        const decrypted = u.decrypt(data.data, data.suffix);
                        const parsedData = JSON.parse(decrypted);
                        setResponseData(parsedData);
                        if (document.getElementById('office') && parsedData?.official_website) {
                            document.getElementById('office')!.innerHTML = parsedData.official_website;
                        }                    } catch (e) {
                        console.error("Decryption error:", e);
                    }
                })
                .catch(error => console.log(error));
        }, []);


        const openLink = (type: string) => {
            if (!responseData) return;
    
            let urlToGo = '';
            switch (type) {
                case 'android_download1':
                    case 'android_download2':
                    case 'android_download3':
                        if(PROD_SITE === "1"){
                            urlToGo = responseData.android_app_download_1;
                        }else if(PROD_SITE === "2"){
                            urlToGo = responseData.android_app_download_2;
                        } else{
                            urlToGo = responseData.android_app_download_3;
                        }
                    break;
                case 'report':
                    urlToGo = responseData.feedback;
                    break;
                case 'telegram':
                    urlToGo = responseData.telegram_group;
                    break;
                case 'business':
                    urlToGo = responseData.merchant;
                    break;
                case 'email':
                    urlToGo = responseData.official_email;
                    break;
                case 'qrcode':
                    urlToGo = responseData.official_qr;
                    break;
                case 'home':
                    // urlToGo = responseData.official_website;
                    urlToGo = window.location.origin;
                    break;
                default:
                    urlToGo = responseData.official_website;
            }
    
            if (!urlToGo.includes('http')) {
                urlToGo = 'https://' + urlToGo;
            }
    
            window.location.href = urlToGo;
        };


        const downloadFile = (type: string) => {
            if (!versionData || !responseData) return;
    
            const params = new URLSearchParams(window.location.search);
            const paramInvite = params.get('invite');
            if (paramInvite) {
                const input = document.createElement('input');
                input.value = paramInvite;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            }
    
            const targetUrl = versionData[type + '_url'] || responseData.android_app_download_1 || 'https://maomiav.com';
            window.location.href = targetUrl;
        };

        const copyText = (type: string) => {
            const targetUrl = versionData[type + '_url'] || responseData.official_website || 'https://maomiav.com';
            const input = document.createElement('input');
            input.value = targetUrl;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        };

    const bannerList = [
        { img: "/assets/images/pc/img_slide1.png", alt: "Slide 1" },
        { img: "/assets/images/pc/img_slide2.png", alt: "Slide 2" },
        { img: "/assets/images/pc/img_slide3.png", alt: "Slide 3" }
    ];

    const handleDotClick = (index: number) => {
        const swiperElement = document.querySelector(".swiper") as HTMLElement & { swiper?: any };

        if (swiperElement?.swiper) {
            swiperElement.swiper.slideToLoop(index); // Call Swiper's method
            setActiveIndex(index); // Update state
        }
    };

    const handleViewClick = (images: string[]) => {
        console.log('got open?')
        setModalImages(images);
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setModalImages([]);
      };
    

    return (
        <div className={`${styles.bgpage} body-dl`} style={{backgroundImage:`(${appendHost("/images/androioddownload/bg.png")}), linear-gradient(rgb(116 62 64 / 78%), rgb(116 62 64 / 77%))`}}>
          <div className={`${styles.csicon}`} onClick={() => openLink('report')}>
          <Img width={100} height={100} alt='logo' src={appendHost("/images/androiddownload/csicon.png")} style={{width: isMobile ? 50 : 50, height: isMobile ? 50 : 50}}/>
          </div>
        <div className={`${styles.mainlogo}`}>
            <Img width={100} height={100} alt='logo' src={appendHost("/images/androiddownload/maomilogo_new1.png")} style={{width: isMobile ? 50 : 70, height: isMobile ? 50 : 70}}/>

            <div className={`${styles.title_wrapper}`}>
                <div className={`${styles.title}`}>
                    猫咪视频
                </div>
                <div className={`${styles.sub_title}`}>
                    您的专属成人片库
                </div>
                
            </div>
            <div>
                <div className={`${styles.download}`} onClick={() => openLink('android_download1')}>
                    <div>
                        <Img width={27} height={27} alt="android" src={appendHost("/images/androiddownload/android_new.png")} style={{width: isMobile ? 19 : 24, height: isMobile ? 19 : 24}}/>
                    </div>
                    <div className={`${styles.download_title}`} >
                        安卓下载
                    </div>

                </div>
                <div className={`${styles.titledesc}`} onClick={() => openLink('home')}>
                    网页在线看
                </div>
            </div>


    </div>
    <div className={`${styles.container}`}>
        <div className={`${styles.center}`}>
            <div className={`${styles.item}`}>
                <div className={`${styles.title}`}>每日更新</div>
                <div className={`${styles.value}`}>2000+</div>
              </div>
              <div className={`${styles.divider}`}></div>
              <div className={`${styles.item}`}>
                <div className={`${styles.title}`}>独家放送</div>
                <div className={`${styles.value}`}>1万+</div>
              </div>
              <div className={`${styles.divider}`}></div>
              <div className={`${styles.item}`}>
                <div className={`${styles.title}`}>优质无广</div>
                <div className={`${styles.value}`}>5万+</div>
              </div>
              <div className={`${styles.divider}`}></div>
              <div className={`${styles.item}`}>
                <div className={`${styles.title}`}>私密观影</div>
                <div className={`${styles.value}`}>30万+</div>
              </div>
        </div>

      </div>

      <div className={`${styles.notecontainer}`}>
      <div className={`${styles.notetitle}`}>
       温馨提示：
      </div>
      <div>
      - 如遇安装失败 请尝试用手机自带浏览器下载或参考下方安装教程
      </div>
      <div>
      - ios版本正在开发中，敬请期待
      </div>
      <div>
      - 如需帮助，请联系在线客服
      </div>
      </div>

      <div className={`${styles.slider}`}>
        <div className={`${styles.slider_wrapper}`}>
            <Img src={appendHost("/images/androiddownload/frame_6.png")}  width={458} height={808}/>
        </div>
        <div className={`${styles.slider_wrapper}`}>
            <Img src={appendHost("/images/androiddownload/frame_7.png")} width={458} height={808}/>
        </div>
        <div className={`${styles.slider_wrapper}`}>
            <Img src={appendHost("/images/androiddownload/frame_8.png")} width={458} height={808}/>
        </div>
        <div className={`${styles.slider_wrapper}`}>
            <Img src={appendHost("/images/androiddownload/frame_9.png")} width={458} height={808}/>
        </div>
      </div>
      <div className={`${styles.install}`}>
        <h1 className={`${styles.title}`}>手机报读禁止安装解决方法</h1>
        {[
          {
            icon: "/images/androiddownload/gear1.png",
            text: "允许未知来源APP安装设置",
            images: [
              "/images/androiddownload/gear2_1.png",
            ],
          },
          {
            icon: "/images/androiddownload/gear1.png",
            text: "禁止安装恶意软件设置",
            images: ["/images/androiddownload/gear2_2.png",],
          },
          {
            icon: "/images/androiddownload/huawei1.png",
            text: "华为手机安装APP和杀毒设置",
            images: ["/images/androiddownload/huawei2_1.png",],
          },
          {
            icon: "/images/androiddownload/huawei1.png",
            text: "华为纯净模式关闭操作",
            images: ["/images/androiddownload/huawei2_3.png",],
          },
          {
            icon: "/images/androiddownload/huawei1.png",
            text: "华为恶意应用",
            images: ["/images/androiddownload/huawei2_4.png",],
          },
          {
            icon: "/images/androiddownload/huawei1.png",
            text: "鸿蒙纯净版安装APP和杀毒设置",
            images: ["/images/androiddownload/huawei2_2.png",],
          },
          {
            icon: "/images/androiddownload/xiaomi.png",
            text: "小米手机安装APP和杀毒设置",
            images: ["/images/androiddownload/xiaomi_2.png",],
          },
          {
            icon: "/images/androiddownload/oppo.png",
            text: "OPPO手机安装APP和杀毒设置",
            images: ["/images/androiddownload/oppo_2.png",],
          },
          {
            icon: "/images/androiddownload/vivo.png",
            text: "VIVO手机安装APP和杀毒设置",
            images: ["/images/androiddownload/vivo_3.png",],
          },
          {
            icon: "/images/androiddownload/meizu.png",
            text: "MEIZU手机安装APP和杀毒设置",
            images: ["/images/androiddownload/meizu_2.png",],
          },
          {
            icon: "/images/androiddownload/tencent.png",
            text: "腾讯手机管家安全设置",
            images: ["/images/androiddownload/tencent_2.png",],
          },
        ].map(({ icon, text, images }, index) => (
          <div className={`${styles.card}`} key={index}>
            <div className={`${styles.icon}`}>
              <Img src={appendHost(icon)} alt={text} />
            </div>
            <div className={`${styles.text}`}>{text}</div>
            <button
              className={`${styles.button}`}
              onClick={() => handleViewClick(images)}
              aria-label={`View details for ${text}`}
            >
              查看
            </button>
          </div>
        ))}
      </div>

            {/* Modal for viewing images */}
            {isModalOpen && (
        <div className={`${styles.modal}`} onClick={closeModal}>
          <div
            className={`${styles.modalContent}`}
            onClick={(e) => e.stopPropagation()}
          >
            {/* <button className={`${styles.closeButton}`} onClick={closeModal}>
              返回
            </button> */}
            <div className={`${styles.imageContainer}`} onClick={closeModal}>
              {modalImages.map((image, index) => (
                <Img
                  key={index}
                  src={appendHost(image)}
                  alt={`Modal image ${index + 1}`}
                  style={{maxWidth:'100%', borderRadius:'8px'}}
                  width={1000}
                  height="auto"
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.download_container}`}>
      <img src={qrCodeDataUrl} width={130} height={130} alt="qrcode" style={{background:'white', padding:'5px', marginTop:'60px', borderRadius:'10px'}}/>

        {/* <Img width={130} height={130} src={appendHost("/images/download/qrcode.png")} alt="qrcode" style={{background:'white', padding:'5px', marginTop:'100px', borderRadius:'10px'}}/> */}
        <p className={`${styles.qrcodetitle}`}>请打开手机浏览器扫一扫下载</p>
      </div>
      <div className={`${styles.download_btn}`} onClick={() => openLink('android_download1')}>
        <div className={`${styles.download_title}`} >
            立刻下载APP
        </div>
      </div>
      

    
</div>

    );
};

export default AndroidDownload;
