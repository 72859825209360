import { FC, useState, useCallback, useEffect } from "react";
import styles from "./styles/help.module.css"
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import useUser from "@/hooks/useUser"
import useRoleAuth from "@/hooks/useRoleAuth"
import useAxios from "@/hooks/useAxios"




const Help: FC = () => {
    // useRoleAuth()
    const [user] = useUser()
    const { req } = useAxios("index/other_details", "post");

    const [data, setData] = useState<any>()
    
    const goDns = () => window.open("https://help.bancon.cc/mm/dns")

        const fetchDetails = useCallback(async (page?: any) => {
            const res = await req();

            setData(res?.data?.config)
        }, [user])

            useEffect(() => {
                fetchDetails()
            }, [fetchDetails])
    
    return (
        <div>
            <div className={styles.m}>
                <div className={styles.main_title}>永久访问教程</div>
                <div className={styles.sec}>为了确保您在我们平台的观影体验更加流畅和安全，我们推荐您使用下列浏览器，除了提供更快的加载速度，还能有效避免某些功能无法正常显示的问题。 搜狗浏览器、火狐浏览器</div>

                <div className={`${styles.padding}`}>
                    <span className={styles.sub_title}>回家的路（本公告最新更新日期：2024年12月30日）</span>
                </div>
                <div className={`${styles.light_g}`}>
                    <span className={styles.dark_title}>最新入口:</span>
                    <span className={styles.dt} onClick={() => window.open(window.location.origin)}>{window.location.origin}</span>
                    <div className={styles.dark_sub_title}>
                    1.不需要翻墙，使用时请尽量关闭vpn
                    </div>
                    <div className={styles.dark_sub_title}>
                    2.打开手机流量或者wifi
                    </div>
                    <div className={styles.dark_sub_title}>
                    3.某些地区会被运营商限制导致无法打开，请尝试更换其他浏览器
                    </div>
                </div>
                <div className={`${styles.flex_desc}`}>
                    <div>
                    <div>
                <span className={styles.dark_title}>永久入口:</span>
                <span className={styles.dt} onClick={() => window.open(data?.permanet_url)}>{data?.permanet_url}</span>
                </div>
                {/* <div>
                <span className={styles.dark_title}>永久主页:</span>
                <span className={styles.dt} onClick={() => window.open(data?.permanet_url)}>{data?.permanet_url}</span>
                </div> */}
                <div>
                <span className={styles.dark_title}>需要使用vpn或梯子等科学上网方式:</span>
                </div>
                    </div>
                    <div>
                    <div>
                <span className={styles.dark_title}>官方App下载地址:</span>
                <span className={styles.dt} onClick={() => window.open(window.location.origin + '/download')}>{window.location.origin + '/download'}</span>
                </div>
                {/* <div>
                <span className={styles.dark_title}>官方免费微信qq吃呱群:</span>
                <span className={styles.dt} onClick={() => window.open(data?.wechat_qq_url)}>{data?.wechat_qq_url}</span>
                </div> */}
                {/* <div>
                <span className={styles.dark_title}>18涩导航:</span>
                <span className={styles.dt} onClick={() => window.open(data?.adult_navigation_url)}>{data?.adult_navigation_url}</span>
                </div> */}
                    </div>
                    <div>
                    <div>
                <span className={styles.dark_title}>官方电报（telegram）群:</span>
                <span className={styles.dt} onClick={() => window.open(data?.telegram_group_url)}>{data?.telegram_group_url}</span>
                </div>


                {/* <div>
                <span className={styles.dark_title}>官方推特:</span>
                <span className={styles.dt} onClick={() => window.open(data?.twitter_url)}>{data?.twitter_url}</span>
                </div> */}
                    </div>





                </div>
                <div className={`${styles.padding}`}>
                    <span className={styles.sub_title}>新地址获取方法一：</span>
                </div>
                <div className={`${styles.grey_b}`}>
                    <div style={{paddingBottom:'5px'}}> 
                        <span><a className={styles.email_content} href={`mailto:${data?.email_contact}`}>{data?.email_contact}</a></span> 
                    </div>
                    <div className={styles.dark_sub_title}>
                    发送任意内容至邮箱获取最新域名链接。
                    </div>
                    {/* <div className={styles.dark_sub_title}>
                    由于每日自动回复最新地址数量加大会导致出现上限问题从而无法收到最新地址，可发送下面备用邮箱获取备用自动回复最新地址邮箱
                    </div> */}
                    {/* <div>
                        <span><a className={styles.email_content_bak} href="mailto:18hlw@pm.me">18hlw@pm.me</a></span> 
                    </div> */}
                </div>
                {/* <div className={`${styles.padding}`}>
                    <span className={styles.sub_title}>新地址获取方法二：</span>
                </div>
                <div className={`${styles.grey_b}`}>
                    <div style={{paddingBottom:'10px'}}>
                    <span className={styles.dt_method2} onClick={() => window.open(data?.github_url)} >{data?.github_url}</span>

                    </div>
                <div className={`${styles.flex}`}>
                    <div>
                    <div className={styles.dark_sub_title}>
                    打开此网页快速查看最新地址 */}
                    {/* 分享活动,天天领现金，规则查看 */}
                    {/* </div>
                    <div>
                <span className={styles.dark_title}>官方通道：</span>
                <span className={styles.dt} onClick={() => window.open(data?.backup_address_url)}>{data?.backup_address_url}</span>
                </div>
                <div>
                <span className={styles.dark_title}>TG  频道：</span>
                <span className={styles.dt} onClick={() => window.open(data?.telegram_channel_url)}>{data?.telegram_channel_url}</span>
                </div>
                    </div>
                    <div> */}
                    {/* <div className={styles.tougao}>
                    我们有偿投稿！！
                    </div> */}
                    {/* <div>
                <span className={styles.dark_title}>邮箱投稿：</span>
                <span className={styles.email}><a className={styles.email_content} href={`mailto:${data?.contribute_email}`}>{data?.contribute_email}</a></span> 
                </div> */}
                {/* <div>
                <span className={styles.dark_title}>电报投稿：</span>
                <span className={styles.dt} onClick={() => window.open(data?.contribute_telegram_url)} style={{color:'#A9A9A9'}}>{data?.contribute_telegram_url}</span>
                </div> */}
                    {/* </div>
                </div>
                </div> */}

            </div>
        </div>
    )
}
export default Help