import Img from "@/components/img";
import { appendHost } from "@/const/append";


const Fenxiang= () => {
    return (
        // <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        // <path d="M5.68127 14.1571C5.68127 15.1579 5.84768 16.0485 6.12659 16.9391C7.23752 13.7118 10.1883 11.375 13.6922 11.375V12.3758C13.6922 13.679 15.1899 14.4149 16.2211 13.618L21.15 9.80942C21.9633 9.1813 21.9633 7.95317 21.15 7.32505L16.2235 3.51645C15.1922 2.71958 13.6946 3.45552 13.6946 4.75864V5.81333C9.26721 5.8438 5.68127 9.55864 5.68127 14.1571Z" fill="currentColor"/>
        // <path d="M20.8289 13.468C20.2054 13.468 19.7015 13.9719 19.7015 14.5953V17.6891C19.7015 18.6266 18.9421 19.3859 18.0046 19.3859H6.0398C5.1023 19.3859 4.34292 18.6266 4.34292 17.6891V8.7875C4.34292 7.85 5.1023 7.09063 6.0398 7.09063H7.2023C7.82573 7.09063 8.32964 6.58672 8.32964 5.96328C8.32964 5.33984 7.82573 4.83594 7.2023 4.83594H6.0398C3.85776 4.83594 2.09058 6.60547 2.09058 8.78516V17.6867C2.09058 19.8687 3.86011 21.6359 6.0398 21.6359H18.0046C20.1867 21.6359 21.9539 19.8664 21.9539 17.6867V14.593C21.9562 13.9719 21.4523 13.468 20.8289 13.468Z" fill="currentColor"/>
        // </svg>
        <Img src={appendHost("/images/fenxiang.png")} width={20} height={20} cls={``} />

    )
}
export default Fenxiang;