import moment from "moment";
import styles from "./styles/footer.module.css"
import { Link } from "react-router-dom";
import Img from "./img";
import { appendHost } from "@/const/append";

import { useCallback, useState } from "react";
import u from "@/util";

const Footer = () => {
    const [open, setOpen] = useState<number>(0);

    const urlJump = useCallback((url: any) => {
        if (url) {
            return u.open(`/information${url}`)
        }
        return u.open("/home")
    }, [])

    return (
        <div>
            <div className={`${styles.footer} grid gap10`}>
                {/* <div>关闭飘浮</div> */}
                <div>警告：如果您未滿18歲或您當地法律許可之法定年齡、或是對情色反感或是衛道人士建議您離開本站！ 本站歸類為限制級、限定為成年者已具有完整行為能力且願接受本站內影音內容、及各項條款之網友才可瀏覽，未滿18歲謝絕進入。</div>
                <div>
                    本站已遵照「iWIN網路內容防護機構」進行分類，如有家長發現未成年兒童／少年瀏覽此站、請按照此方法過濾本站內容『網站分級制度』
                </div>

                <div className={styles.inner}>
                    {title.map((x, idx) => {
                        return <div key={idx}>
                            <div className={styles.title}>{x}</div>
                            <div>
                                {innerContent[idx].map((t: any, idx2) => {
                                    return <div onClick={() => {
                                        if (t?.event) {
                                            return t.event()
                                        }
                                        return urlJump(t?.url)
                                    }} className={styles.child} key={idx2}>{t.name}</div>
                                })}
                            </div>
                        </div>
                    })}
                </div>

                <div className={styles.inner_mobile}>
                    {title.map((x, idx) => {
                        return <div key={idx} onClick={() => setOpen(idx + 1)}>
                            <div className="fl align_center justify_between">
                                <div className={styles.title}>{x}</div>
                                <Img width={12} height={6} src={appendHost("/images/vector.png")} />
                            </div>
                            <div>
                                {open === idx + 1 && innerContent[idx].map((t: any, idx2) => {
                                    return <div onClick={() => {
                                        if (t?.event) {
                                            return t.event()
                                        }
                                        return urlJump(t?.url)
                                    }} className={styles.child} key={idx2}>{t.name}</div>
                                })}
                            </div>
                        </div>
                    })}
                </div>

                <div className={styles.time_mobile}>
                    <a className={styles.timea} href="https://www.maomiav.com/">&copy; maomiav.com, {moment().year()}</a>
                    <Link to="/home">
                        <Img width={82} height={18} src={appendHost("/images/header/logo1.png")} />
                    </Link>
                </div>
            </div>
            <div className={styles.time}>
                <a className={styles.timea} href="https://www.maomiav.com/">&copy; maomiav.com, {moment().year()}</a>
                <Link to="/home">
                    <Img width={82} height={18} src={appendHost("/images/header/logo1.png")} />
                </Link>
            </div>
        </div>
    )
}

export default Footer;

const title = ["信息", "加入我们", "支持与帮助", "连接"]
const innerContent = [
    [
        { name: "条款和条件", url: "/terms" },
        { name: "隐私政策", url: "/privacy" },
        { name: "DMCA", url: "/dmca" },
        { name: "2257", url: "/2257" },
        { name: "EU DSA", url: "/dsa" },
    ],
    [
        { name: "内容合作商", event: u.tgOpen },
        // { name: "推广", event: u.tgOpen, url: "/" },
        // { name: "网站站长", event: u.tgOpen, url: "/" },
        // { name: "广告", event: u.tgOpen, url: "/" },
    ],
    [
        { name: "内容移除", event: u.kefuOpen, url: "/content-removal" },
        { name: "寻取帮助", event: u.kefuOpen },
        { name: "信任与安全", url: "/trust-safety" },
        { name: "家长控制", url: "/parental-control" },
        { name: "反馈", event: u.kefuOpen },
    ],
    [
        { name: "海外永久域名", event: () => {
            return window.open("https://www.maomiav.com/")
        }, url: "/" },
    ],
]