import { useCallback, useEffect } from "react"
import u from "@/util"

const useRoleAuth = () => {
    const init = useCallback(() => {
        if (!u.isLogin()) {
            return window.location.href = u.formLogin(true);
        } 
    }, [])

    useEffect(() => {
      init()
    }, [init])
}
export default useRoleAuth