import React, { FC } from "react";
import enterStyles from "@/components/styles/dialog.module.css";
import html2canvas from "html2canvas";
import Img from "./img"
import { appendHost } from "@/const/append";

const Dialog: FC<{ onClose: () => void; qrCodeDataUrl: string }> = ({ onClose, qrCodeDataUrl }) => {
  
  const downloadScreenshot = () => {
    const dialogElement = document.getElementById('dialog');
    
    if (dialogElement) {
      html2canvas(dialogElement).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = 'dialog-screenshot.png';
        link.click();
      });
    }
  };

  return (
    // <div className={enterStyles.dialogOverlay} id="dialog">
    //   <div className={enterStyles.dialogContent}>
    //     <div className={enterStyles.closeBtn} onClick={onClose}>
    //       ✖
    //     </div>
    //     <div className={enterStyles.qrCodeContainer}>
    //       <img src={qrCodeDataUrl} alt="QR Code" width={200} height={200} />
    //     </div>
    //     <div>Save the QR Code or scan it</div>
    //     <button onClick={downloadScreenshot}>Download Screenshot</button>
    //   </div>
    // </div>
    <div className={enterStyles.dialogOverlay} id="dialog">
      <div style={{position:'relative'}}>
        <div>
        <Img src={appendHost("/images/qrbg.png")} width={340} height={600} />
        <div className={enterStyles.logo}>
        <Img src={appendHost("/images/header/logo.png")} height={30} width={140}/>      </div>
        </div>
     <div className={enterStyles.btnclose} onClick={onClose}>
        <Img src={appendHost("/images/icon/close_new.png")} height={32} width={32}/>
     </div>
        <div className={enterStyles.bgwhite}>
          <div className={enterStyles.topdesc}>
            -防丢失找回凭证-
          </div>
          <div className={enterStyles.title}>
            猫咪二维码
          </div>
          <div className={enterStyles.qrinternal}>
            <div>
            <Img src={appendHost("/images/qrinternalbg.png")} width={150} height={150}/>

            </div>
            <div>
            <img src={qrCodeDataUrl} alt="QR Code" width={110} height={110}  className={enterStyles.qrcodeinternal}/>

            </div>
          </div>
          <div className={enterStyles.pt20}>
            <div>
            <span className={enterStyles.desctitle}>
              回家地址：
            </span >
            <span onClick={() => window.open('https://967455ee6aca.com')} className={enterStyles.descurl}>967455ee6aca.com</span>
            </div>
            <div>
            <span className={enterStyles.desctitle}>
              电子邮箱：
            </span >
            <span><a className={enterStyles.descurl} href="mailto:maomiav@gmail.com">maomiav@gmail.com</a></span>            </div>
            <div>
            <span className={enterStyles.desctitle}>
            永久域名：
            </span >
            <span onClick={() => window.open('https://www.maomiav.com')} className={enterStyles.descurl}>www.maomiav.com</span>
            </div>


          </div>
          <div className={enterStyles.warning}>
          *如果截图保存失败，请手动截图保存！
          </div>
          <div style={{paddingTop:'10px'}}>
          <button onClick={downloadScreenshot} className={enterStyles.btnsave}>截图保存</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Dialog;
