import { FC, useCallback, useEffect, useState } from "react";
import Button from "@/components/button";
import styles from "@/components/styles/modal.module.css"
import feedBackStyles from "@/components/styles/feedback.module.css"
import useModal, { EModalTypes, IModal, modalSubject$ } from "@/hooks/useModal";
import useAxios from "@/hooks/useAxios";
import { PROD_SITE } from "@/const/prod.const";
// import DataList from "../dataList";
import u, { ELocalKey, ProjectInfo } from "@/util";
import { R } from "@/req";
import { getQuestionDetail, postQuestionDetail } from "@/apis/question";
import Select from "../select";

const FeedbackModal: FC<IModal> = () => {
    const [comment, setComment] = useState<string>("");
    const [like, setLikes] = useState<number>(2)
    const [modalData, closeModal] = useModal();
    const [selectPlace, setSelectPlace] = useState<string>("");
    const [selectLine, setSelectLine] = useState<string>("")
    const [isClick, setIsClick] = useState<boolean>(false)
    const [formData, setFormData] = useState<any>({})
    const { req } = useAxios("index/feedback", 'post', true);

    // const initFeedback = useCallback(async () => {
    //     if (!modalData?.show) return
    //     const token = u.getLocalData(ELocalKey.__QTKN, true) || u.getQtknToken();
    //     if (token) {
    //         const res2 = await R.request(getQuestionDetail({ token, question: "current" }), false, false, 1);
    //         if (res2?.data) {
    //             setFormData({ ...res2?.data })
    //         }
    //     }
    // }, [modalData])

    // useEffect(() => {
    //     initFeedback()
    // }, [initFeedback])

    const goFeedBack = useCallback(async () => {
        const token = u.getLocalData(ELocalKey.__QTKN, true);
        if (!token) return;
        setIsClick(true)
        let isValid = true;
        let msg = ""
        if (!comment) {
            isValid = false;
        }
        if (!comment) {
            isValid = false;
        }
        if (!isValid) {
            // statusModalSubject$.next({
            //     id: EModalTypes.STATUS_NORMAL,
            //     innerTitle: msg,
            //     btn: true,
            // })
            return;
        }
        // await req({
        //     like,
        //     comment,
        //     site: `${PROD_SITE}_1`,
        //     time: new Date().getTime(),
        //     place: selectPlace,
        //     line: selectLine,
        //     domain: window?.location?.origin
        // })

        const _opt = formData.option.find((_: any, i: number) => i === like)
        const params = {
            token,
            project_id: ProjectInfo.ID,
            // feedback_question_id: "current",
            site: `${PROD_SITE}_1`,
            option: _opt,
            comment,
            place: selectPlace,
            network: selectLine,
            like,
            domain: window?.location?.origin
        }

        await R.request(postQuestionDetail(params), false, false, 1);

        modalSubject$.next({
            id: EModalTypes.NORMAL,
            innerTitle: "感谢您的反馈！",
            btn: true,
        })
        closeModal()
    }, [comment, like, selectLine, selectLine])

    return (
        modalData?.show && modalData?.id === EModalTypes.FEEDBACK && <div className={styles.common}>
            <div className={`${styles.body} ${feedBackStyles.m} cls_common`}>
                <div onClick={closeModal} className={styles.close}>X</div>
                <div className={styles.content}>
                    <div className={`${feedBackStyles.title}`}>{formData?.name}</div>
                    <div className={`${feedBackStyles.feedback_inner_title} mt10`}>{modalData?.innerTitle}</div>
                    <div className={`mt20 mb10 ${styles.fb_select}`}>
                        {/* <DataList placeholder="选择线路" list={line} onChange={(e => setSelectLine(e.target.value))} /> */}
                        <Select list={line.map((x) => ({ name:x, value: x }))} onChange={(e => setSelectLine(e))} />
                        {isClick && !selectLine && <div className="left red">*请选择线路</div>}
                    </div>
                    <div className={styles.fb_select}>
                        {/* <DataList placeholder="选择地区" list={place} onChange={(e => setSelectPlace(e.target.value))} /> */}
                        <Select list={place.map((x) => ({ name:x, value: x }))} onChange={(e => setSelectPlace(e))} />
                        {isClick && !selectPlace && <div className="left red">*请选择地区</div>}
                    </div>
                    {
                        Array.isArray(formData?.option) && formData.option.map((x: any, i: number) => {
                            return <div key={i}>
                                <div className={`fl align_center gap10 ${i === 0 && 'mt10'}`}>
                                    <input onChange={() => setLikes(i)} checked={like === i} className={feedBackStyles.radio} name="like" type="radio"></input>
                                    <div className={feedBackStyles.text}>{x}</div>
                                </div>
                            </div>
                        })
                    }
                    <div>
                        <textarea onChange={(e) => setComment(e.target.value)} placeholder="欢迎填写您尊贵的意见～" rows={4} className={`${feedBackStyles.textarea}`}></textarea>
                        {isClick && !comment && <div className="left red">*请填写意见</div>}
                    </div>
                    {modalData?.btn && <div className="mt20">
                        <Button event={u.debounce(goFeedBack)} cls="btn_sty2 w100">提交</Button>
                    </div>}
                </div>
            </div>
        </div>
    )
}
export default FeedbackModal;

const place = ["河南", "山东", "北京", "成都", "重庆", "青海", "湖南", "陕西", "浙江", "安徽", "河北", "山西", "辽宁", "吉林", "黑龙江", "江苏", "海南", "台湾", "湖北", "四川", "贵州", "云南", "甘肃", "江西", "福建", "北京", "上海", "天津", "香港", "澳门", "广西", "西藏", "新疆", "内蒙古", "宁夏", "其他"];
const line = ["移动", "联通", "电信", "铁通", "其他"]